import React from 'react';
import {Avatar, Badge, Card} from "antd";

function AlertCard({title, description, icon, avatarBgTailClass}) {
    return (
        <Card
            style={{cursor: 'pointer'}}
            className="dark:border-neutral-700"
            styles={{body:{padding: 0}}}
        >
            <div className="px-3 py-4 lg:px-6 lg:py-9">
                <div className="flex  gap-3 lg:gap-5 ">
                    {!!icon && (
                        <Badge color="volcano" count="!" size="small" offset={[10,-5]} style={{transform:'scale(1.5)'}}>
                            <Avatar size="large" icon={icon} className={`flex-shrink-0 ${avatarBgTailClass}`}  />
                        </Badge>
                    )}

                    <div>
                        <h2 className={`lg:text-xl tracking-tight font-semibold text-gray-600 dark:text-gray-400`}> {title}</h2>
                        <div className="text-xs lg:text-sm text-gray-500 dark:text-gray-500  pb-1">
                            {description}
                        </div >
                    </div>

                </div>
            </div>
        </Card>
    );
}

export default AlertCard;