import React, {useEffect, useState} from 'react';


function UseScrollY(props) {
    const [scrollY, setScrollY] = useState(0);
    const handleScroll = () => {
        setScrollY( window.scrollY);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollY]);

    return scrollY

}

export default UseScrollY;