import React from 'react';
import {Row,Col} from "antd";
export const justifierLayout = {xs: 22, md: 20, xl: 18, xxl: 16}
function Justifier({ justify = 'center', align = 'baseline', children, ...restProps }) {
    return (
        <Row justify={justify} align={align} {...restProps} {...restProps}>
            <Col {...justifierLayout}>
                {children}
            </Col>
        </Row>
    );
}

export default Justifier;