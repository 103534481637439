import React, {useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import {OverlayContext} from "./CSSOverlayProvider";

useOverlay.propTypes = {
    OverlayContent: PropTypes.element.isRequired,
    parentRef: PropTypes.object
};

function useOverlay(OverlayContent, key,  props={}) {
    const {onClickGenerator} = useContext(OverlayContext)
    return {
        launch: onClickGenerator(key, OverlayContent, props)
    };
}

export default useOverlay;