import questionProps from "../questionProps";
import {ApplicationTypes, MortgageTypes} from "../../../../../../../models";
export function mortgageType(props) {
    return {
        label: "Type of Mortgage",
        text: "Select your requirement",
        subtext: "Select the type of mortgage you need",
        name: "mortgageType",
        answer: {
            type: "select",
            choices: [
                {
                    text: "New mortgage",
                    value: MortgageTypes.PURCHASE,
                },
                {
                    text: "Refinance an existing mortgage",
                    value: MortgageTypes.REFINANCE,
                }
            ]
        },
        model: "mortgage",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `mortgageType`,
        watch: (form, value)=>{
            form.setFieldValue('applicationType', null)
        }
    }
}
export function applicationTypeRefinance(props) {
    return {
        label: 'On Which Property',
        name: 'applicationType',
        text: 'What property will be on the mortgage?',
        model: 'mortgage',
        answer: {
            type: 'select',
            choices: [
                {
                    text: "My own home",
                    value: ApplicationTypes.OWNHOME,
                },
                {
                    text: "A home I rent out",
                    value: ApplicationTypes.LANDLORD,
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('mortgageType') !== MortgageTypes.REFINANCE
        },
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicationType`
    }
}
export function applicationTypePurchase(props) {
    return {
        label: 'Applicant Type',
        name: 'applicationType',
        text: 'What type of purchaser are you?',
        model: 'mortgage',
        hides: (form) => {
            return form.getFieldValue('mortgageType') !== MortgageTypes.PURCHASE
        },
        answer: {
            type: 'select',
            choices: [
                {
                    text: "First Time Buyer",
                    value: ApplicationTypes.FIRSTTIME,
                },
                {
                    text: "Moving Home",
                    value: ApplicationTypes.MOVINGHOME,
                },
                {
                    text: "Buying To Let",
                    value: ApplicationTypes.LANDLORD,
                },
                {
                    text: "Second Time Buyer",
                    value: ApplicationTypes.SECONDTIME,
                }
            ]
        },
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicationType`
    }
}
export function propertyValue(props) {
    return {
        label: 'Property Value',
        name: 'propertyValue',
        text: `Enter the value/price of the property`,
        model: `properties`,
        answer: {
            type: 'euro',
            step: 1000
        },
        rules: [
            {required: true, message: `A value is required`},
            {type: 'number', min: 125000, message: 'Minimum amount is 125,000'},
            {type: 'number', max: 3000000, message: 'Maximum amount is 3,000,000'},
        ],
        target: `properties[${props.mortgage.properties[0].id}].propertyValue`,
    }
}
export function loanRequired(props) {
    const rules = (form) => {
        let r = [
            {required: true, message: 'Enter a loan amount'},
            {type: 'number', min: 100000, message: 'Minimum amount is 100,000'},
        ]
        let val = form.getFieldValue('propertyValue')
        let maxLoan = val ? Math.round(val * 0.9) : 3000000
        console.log({maxLoan})
        r.push({
            type: 'number',
            max: maxLoan,
            message: 'Maximum amount is ' + maxLoan.toLocaleString('en-us')
        })
        return r
    }
    return {
        label: 'Loan Required',
        name: 'loanRequired',
        text: `Enter the loan amount required`,
        model: `properties`,
        answer: {
            type: 'euro',
            step: 1000
        },
        rules,
        target: `requirement.loanRequired`,
    }
}
export function twoApplicants(props) {
    return {
        label: 'Number Of Applicants',
        name: 'twoApplicants',
        text: 'Choose whether there will be a secondary applicant on the application',
        model: 'mortgage',
        answer: {
            type: 'choice',
            choices: [
                {
                    value: false,
                    text: 'One',
                },
                {
                    value: true,
                    text: 'Two',
                }

            ]
        },
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `twoApplicants`,
    }
}
export function applicant2Income(props) {
    return {
        label: 'Applicant 2 Income',
        name: 'applicant2Income',
        text: 'Does the other applicant have an income',
        model: 'mortgage',
        answer: {
            type: 'choice',
            choices: [
                {
                    value: true,
                    text: 'Yes',
                },
                {
                    value: false,
                    text: 'No',
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('twoApplicants') === false
        },
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant2.financial.hasIncome`,
    }
}
export function applicant1Employment(props){
    return {
        label: "Your employment status",
        text: "What is your current employment status?",
        name: "applicant1Employment",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Employed",
                    value: "EMPLOYED"
                },
                {
                    text: "Self-Employed",
                    value: "SELFEMPLOYED"
                },
                {
                    text: "Homemaker",
                    value: "HOMEMAKER"
                },
                {
                    text: "Student",
                    value: "STUDENT"
                }
            ]
        },
        model: "employment",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant1.employment.employmentType`,
    }
}
export function applicant2Employment(props){
    return {
        label: "The other applicant's employment status",
        text: "What is the other applicant's current employment status?",
        name: "applicant2Employment",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Employed",
                    value: "EMPLOYED"
                },
                {
                    text: "Self-Employed",
                    value: "SELFEMPLOYED"
                },
                {
                    text: "Homemaker",
                    value: "HOMEMAKER"
                },
                {
                    text: "Student",
                    value: "STUDENT"
                }
            ]
        },
        model: "employment",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        hides: (form)=>{
            return form.getFieldValue('twoApplicants') !== true
        },
        target: `applicant2.employment.employmentType`,
    }
}
export function cohabiting(props) {
    return {
        label: 'Cohabiting',
        name: 'cohabiting',
        text: 'Do you and the other applicant live together?',
        model: 'mortgage',
        answer: {
            type: 'choice',
            choices: [
                {
                    value: true,
                    text: 'Yes',
                },
                {
                    value: false,
                    text: 'No',
                }
            ]
        },
        hides: (form) => {

             return form.getFieldValue('twoApplicants') === false || form.getFieldValue('applicationType') === ApplicationTypes.LANDLORD
        },
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `requirement.cohabiting`,
    }
}

function setupProfile(props) {
    const betterProps = questionProps(props)
    return [

        applicationTypeRefinance(betterProps),
        applicationTypePurchase(betterProps),
        loanRequired(betterProps),
        propertyValue(betterProps),
        twoApplicants(betterProps),
        // applicant2Income(betterProps),
        cohabiting(betterProps),
    ]
}

export default setupProfile;