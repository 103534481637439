import React from 'react';
import Overlay from "../../../assets/elements/Overlay";
import SectionsList from "../../../assets/elements/secions-list";
import {Card, Empty, List} from "antd";
import useMortgage from "../../../data-source/hooks/useMortgage";
import useMortgageDocuments from "../../../data-source/hooks/useMortgageDocuments";
import _useColorRange from "../../../assets/elements/_useColorRange";
import {FilePdfOutlined, FileSearchOutlined} from "@ant-design/icons";
import CardTitle from "../../../assets/elements/cards/CardTitle";
import CardDescription from "../../../assets/elements/cards/CardDescription";
import dayjs from "dayjs";

function Index(props) {
    const {lenderDocs,sussdDocs} = useMortgageDocuments()
    const colorRange = _useColorRange('#c5c5c5')

    const itemRenderer = (item) => (
        <List.Item
            key={item.key}
        >
            <div className="flex w-full px-4 gap-3 cursor-pointer" onClick={item.onClick}>
                <FilePdfOutlined className="text-2xl text-gray-500"/>
                <div className="flex flex-col">
                    <CardTitle>{item.title}</CardTitle>
                    <CardDescription>{dayjs(item.createdAt).format("D MMM YYYY")}</CardDescription>
                </div>
            </div>

        </List.Item>
    )
    return (
        <>
            <Overlay
                icon={<FileSearchOutlined/>}
                title={'Your Documents'}
                description={'Here you\'ll find your policy documents, terms and conditions and other important documentation'}
                colorRange={colorRange}

            >
                <div className="flex flex-col gap-4">
                    {lenderDocs.length > 0 && (
                        <Card styles={{body:{padding: 0}}} title={"Lender Documents"}>
                            <List dataSource={lenderDocs} renderItem={itemRenderer} />
                        </Card>
                    )}
                    {sussdDocs.length > 0 && (
                        <Card styles={{body:{padding: 0}}} title={"Suss'd Documents"}>
                            <List dataSource={sussdDocs} renderItem={itemRenderer} />
                        </Card>
                    )}
                    {(sussdDocs.length === 0 && lenderDocs.length===0) && (
                        <Card >
                            <Empty description="There's nothing in here yet"/>
                        </Card>
                    )}

                </div>

            </Overlay>

        </>
    );
}

export default Index;