import React, {useContext} from 'react';
import {DarkModeContext} from "../../../../app/components/screen/DarkModeProvider";

function PageIcon({icon, colorRange}) {
    const circleStyle = {
        width: 70,
        height: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 35,
        background: `linear-gradient(45deg, ${colorRange[17]}, ${colorRange[19]})`,
        border: `3px solid ${colorRange[29]}`,
        color: '#fff',
        fontSize: '2rem'
    }
    return (
        <span style={circleStyle}>
            {icon}
        </span>
    );
}

export default PageIcon;