import React, {useContext, useEffect,} from 'react';

import {Avatar,} from "antd";

import {LeftOutlined} from "@ant-design/icons";

import './overlay.css'
import UploadSectionOverlay from "../../../../products/mortgage/components/overlay-parts/setup/affordability-uploads/UploadSectionOverlay";


export const OverlayContext = React.createContext({});

function OverlayProvider(props) {
    const [closing, setClosing] = React.useState([])
    const [overlays, setOverlays] = React.useState([])

    const add = (overlay) => {
        setOverlays(s => [
            ...s,
            {
                ...overlay,
                ref: React.createRef()
            }
        ])
    }
    useEffect(() => {
        if (overlays.length > 0){
            document.body.style.overflow = 'hidden'
        }
        else{
            document.body.style.overflow = 'auto'
        }
    },[overlays.length])
    const finaliseClosed = (key) => {
        setOverlays(s => s.filter(o => o.key !== key))
        setClosing(s => s.filter(k => k !== key))
    }

    const removeTop = () => {

        let overlay = overlays[overlays.length - 1]
        if (!overlay) return
        setClosing(s => [...s, overlay.key])
    }
    const remove = (key) => {
        setClosing(s => [...s, key])
    }
    /*
    However, HTML component's x, y, width, height, top, left, right and bottom can animate using any of px, rem, % etc
     */


    return (
        <OverlayContext.Provider value={{
            add,
            remove,
            removeTop,
            finaliseClosed,
            onClickGenerator: (key, Overlay, overlayProps) => (e) => {
                const x = e.clientX // Get the x-coordinate relative to the button
                const y = e.clientY
                const config = {
                    key,
                    children: ()=><Overlay {...overlayProps}/>,
                    x,
                    y
                }
                add(config)
            },
            Overlays: overlays.map((overlay, index) => {
                return <CSSContainer isTop={index === overlays.length -1} key={overlay.key} overlay={overlay} closing={closing.includes(overlay.key)}/>
            })
        }}>

            {props.children}

        </OverlayContext.Provider>
    );
}

function CSSContainer({overlay, isTop, closing = false}) {
    const [fixed,setFixed] = React.useState(false)
    const [active, setActive] = React.useState(false)
    const {remove, finaliseClosed} = React.useContext(OverlayContext)
    const radius = Math.sqrt((window.innerWidth / 2) ** 2 + (window.innerHeight / 2) ** 2);
    const paddingY = radius - (window.innerHeight / 2)
    const paddingX = radius - (window.innerWidth / 2)
    const borderWidth = 0
    const motion = {
        circle: {
            initial: {
                top: overlay.y,
                left: overlay.x,
                borderWidth: 0,
                height: 0,
                width: 0,
                paddingLeft: 0,
                paddingTop: 0,
            },
            animate: {
                top: (window.innerHeight / 2) - (radius + borderWidth),
                left: (window.innerWidth / 2) - (radius + borderWidth),
                width: (radius + borderWidth) * 2,
                height: (radius + borderWidth) * 2,
                paddingLeft: paddingX,
                paddingTop: paddingY,
                borderWidth,
            },
        },
        overlay: {
            initial: {
                marginLeft: isNaN(overlay.x) ? 0 : overlay.x * -1,
                marginTop: isNaN(overlay.y) ? 0 : overlay.y * -1,
            },
            animate: {
                marginLeft: 0,
                marginTop: 0,
            }
        }
    }
    useEffect(() => {
        if (closing) {
            setFixed(false)
            setActive(false)
        }
    }, [closing]);

    const handleTransitionEnd = () => {
        if (closing) {
            return  finaliseClosed(overlay.key)
        }
        setFixed(true)
        if (overlay.ref.current){
            overlay.ref.current.style.overflow = 'auto'
        }
    }
    useEffect(() => {
        setTimeout(() => setActive(true), 10)
    }, []);
    return (
        <div className={active ? 'transition' : ''}>
            <div className={`motion-circle`} style={active ? motion.circle.animate : motion.circle.initial} onTransitionEnd={handleTransitionEnd}>
                <div ref={overlay.ref} className={`motion-overlay ${fixed? 'fixed':''} ${isTop ? 'overflow-auto':'overflow-hidden'}`} style={active ? motion.overlay.animate : motion.overlay.initial}>
                    <div className="mb-4">
                    {overlay.children()}
                    </div>
                    <div className="fixed p-4" style={{top: 0, left: 0}}>

                        <Avatar style={{cursor: "pointer"}} icon={<LeftOutlined/>} size="large"
                                onClick={() => remove(overlay.key)}/>

                    </div>
                </div>
            </div>
            <div className="overflow-auto"></div>
            <div className="overflow-hidden"></div>
        </div>

    )
}

export default OverlayProvider;