import React from 'react';
import {Badge, List} from "antd";
import CardTitle from "../cards/CardTitle";
import CardStatus from "../cards/CardStatus";
import CardDescription from "../cards/CardDescription";
import {CheckCircleOutlined, CheckOutlined} from "@ant-design/icons";
import {green} from "@ant-design/colors";

function ListItem({item}) {
    let myStatus = ''
    let myDot= null
    if (!item.error){

            switch (item.percent){
                case 0:
                    myStatus = 'Not Started'
                    break;
                case 100:
                    myStatus = 'Completed'
                    myDot = "success"
                    break;
                default:
                    myStatus = 'In Progress'
                    myDot = "warning"
                    break;
            }
    }
    else{
        myStatus = item.error
        myDot = "error"
    }

    function MyListItem(){
        return (
            <List.Item className="bg-white dark:bg-neutral-800" onClick={item.onClick}>
                {
                    item.key==='all-complete' ? (
                        <div className="flex w-full">
                            <CheckCircleOutlined className="mt-2 pe-4 hidden sm:inline text-lime-600 dark:text-lime-700" style={{fontSize: 40}}/>
                            <div className="flex flex-col w-full  py-1 ">
                                <div className="flex justify-between items-start">
                                    <CardTitle> {item.title}</CardTitle>
                                    <CardStatus dot={myDot}>{myStatus}</CardStatus>
                                </div>
                                <CardDescription>{item.description}</CardDescription>
                            </div>
                        </div>

                    ) : (<div className="flex flex-col w-full  py-1 ">
                        <div className="flex justify-between items-start">
                            <CardTitle> {item.title}</CardTitle>
                            <CardStatus dot={myDot}>{myStatus}</CardStatus>
                        </div>
                        <CardDescription>{item.description}</CardDescription>
                        </div>)}

            </List.Item>)
    }

    return <MyListItem/>

}

export default ListItem;