import React, {useContext, useEffect, useRef, useState} from 'react';
import {Mortgage} from "./MortgageProvider";

export const MortgageProgress = React.createContext(null);
function MortgageProgressProvider(props) {
    const mortgage = useContext(Mortgage)
    const [state, setState] = useState({
        missing: []
    })

    const generateKey = (type, stage, section, index) => {
        return `${type}-${stage}-${section}-${index}`
    }

    const createRowObject = ({type, stage, section, index})=>{
        const pairs = {
            applicationStage: stage,
            sectionType: type,
            sectionName: section,
            mortgageID: mortgage.id
        }
        if (index) {
            pairs.sectionIndex = index
        }
        return {
            target: 'progress',
            pairs
        }
    }
    const addRecordToMissing = (type, stage, section, index)=>{
        const key = generateKey(type, stage, section, index)
        let found = state.missing.find(it=>it.key===key)
        if (!found){
            setState(prevState => {
                return {
                    ...prevState,
                    missing: [...prevState.missing, {key, config: {type, stage, section, index}}]
                }
            })

        }
    }
    const creatingRef = useRef([])
    const creatingTimer = useRef(null)
    useEffect(()=>{
        clearTimeout(creatingTimer.current)
        creatingTimer.current = setTimeout(()=> {
            if (state.missing.length > 0) {
                state.missing.forEach(missing => {
                    if (creatingRef.current.indexOf(missing.key) === -1) {
                        creatingRef.current.push(missing.key)
                        mortgage.mutate({create: createRowObject(missing.config)})
                    }
                })
            }
        }, 1000)
        return ()=>{
            clearTimeout(creatingTimer.current)
        }
    },[state.missing.length])

    const getSection = (type, stage, section, index)=>{
        const sectionFinder = progress => {
            if (progress.sectionType === type && progress.applicationStage === stage && progress.sectionName === section) {
                if (index) {
                    return progress.sectionIndex === index
                }
                return true
            }
            return false
        }
        const record = mortgage.progress.find(sectionFinder)
        if (!record){
            addRecordToMissing(type, stage, section, index)
            return {creating: true}
        }
        return record
    }

    return (
        <MortgageProgress.Provider
        value={{
            getSection
        }}>
            {props.children}
        </MortgageProgress.Provider>
    );
}

export default MortgageProgressProvider;