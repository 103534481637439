import React from 'react';
import {Button} from "antd";
import {CompassOutlined, CustomerServiceOutlined, FolderOutlined, MailOutlined} from "@ant-design/icons";
import useOverlay from "../../../../../../app/components/screen/overlay/useOverlay";
import Documents from "../../documents";
import MortgageMap from "../../mortgage-map";
function Index(props) {
    const {launch: launchDocuments} = useOverlay(Documents, "Documents")
    const {launch: launchMortgageMap} = useOverlay(MortgageMap, "MortgageMap")
    const items = [
        {
            icon: <FolderOutlined className="text-2xl " style={{display:'flex'}}/>,
            title: 'Docs Folder',
            launch: launchDocuments
        },
        {
            icon: <CustomerServiceOutlined className="text-2xl " style={{display:'flex'}}/>,
            title: 'Phone Us',
            href: "tel:+35316854888"
        },
        {
            icon: <MailOutlined className="text-2xl " style={{display:'flex'}}/>,
            title: 'Mail Us',
            href: "mailto:kevin@sussd.ie"
        },
        {
            icon: <CompassOutlined className="text-2xl " style={{display:'flex'}}/>,
            title: 'Mortgage Map',
            launch: launchMortgageMap
        },
    ]


    function LinkButton({icon, onClick}){
        const params = {}
        if (!!onClick){
            params.onClick = onClick
        }
        return <Button {...params} shape="circle" style={{width:50,height:50}} className="flex items-center justify-center mx-auto py-0 text-gray-300 dark:text-gray-500 dark:bg-transparent" >
            {icon}
        </Button>
    }


    return (
        <div className="flex justify-center gap-12">
            {
                items.map((item, index) => (
                    <div key={index} className="text-center">
                        {item.href && (
                            <a href={item.href} rel="noreferrer">
                                <LinkButton icon={item.icon}/>
                            </a>
                        )}
                        {!item.href && <LinkButton icon={item.icon} onClick={item.launch}/>}
                        <span className="text-xs block text-gray-400 ">{item.title}</span>
                    </div>
                ))
            }
        </div>
    );
}

export default Index;