import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Auth} from "aws-amplify";
import AdminSignIn from "./AdminSignIn";
import CenteredLoading from "../components/screen/CenteredLoading";

export const AuthCheckContext = React.createContext()
function AuthCheck(props) {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search);
    const isFromIframe = !!params?.mortgageId ? searchParams.get('admin-viewer') : false
    const [checkedState, setCheckedState] = useState('not checked')
    const [user, setUser] = useState(false)
    const handleSuccessfulSignin = () => {
        setCheckedState('should reevaluate')
    }
    const checkIfAdminUser = async () => {
        try {
            if (!user){
                let Auser = await Auth.currentAuthenticatedUser()
                const groups = Auser.signInUserSession.idToken.payload['cognito:groups'];
                const isAdmin = groups.includes('admins') || groups.includes('supporters')
                let attributes = {...Auser.attributes, isAdmin}
                if (isAdmin) {
                    if (!isFromIframe) {
                        // admin should not be viewing client app screens directly
                        window.location.href = `https://admin.sussd.ie/${props.product}/${props.productId}`
                    } else {
                        setUser(attributes)
                        setCheckedState('admin viewing from iframe')
                    }
                } else {
                    attributes.applicantId = Auser.signInUserSession.idToken.payload.applicantId
                    setUser(attributes)
                    setCheckedState('default')
                }
            }
        } catch (e) {
            if (isFromIframe) {
                setCheckedState('iframe viewing not logged in')
            }
            else{
                navigate('/sign-in')
            }
        }
    }
    // if admin viewing and not logged in, redirect to custom login
    useEffect(() => {
        checkIfAdminUser()
    }, []);
    useEffect(() => {
        if (checkedState === 'should reevaluate') {
            checkIfAdminUser()
        }
    }, [checkedState]);
    function Provider({children}) {
        return (
            <AuthCheckContext.Provider value={{
                user,
                root: `/${props.product}/${props.productId}`
            }}>
                {props.children}
            </AuthCheckContext.Provider>
        )
    }
    return useMemo(() => {
        switch (checkedState) {
            case 'admin viewing from iframe':
            case 'default':
                return <Provider key="default">{props.children}</Provider>
            case 'iframe viewing not logged in':
                return <AdminSignIn handleSuccessfulSignin={handleSuccessfulSignin}/>
            default:
                return <CenteredLoading/>
        }
    }, [checkedState])
}

export default AuthCheck;