import questionProps from "../questionProps";
import {AssetTypes, Frequencies} from "../../../../../../../models";
import {capitaliseFirst} from "../../../../assets/functions/functions";

export function assetType(props) {
    return {
        text: "What type of account is this?",
        label: "Saving or Investment",
        name: "assetType",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Investment",
                    value: "INVESTMENT"
                },
                {
                    text: "Savings",
                    value: "SAVING"
                }
            ]
        },
        model: "assets",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.assets[${props.index}].assetType`
    }
}
export function institution(props) {
    return {
        name: "institution",
        answer: {
            type: "lender",
        },
        text: "At what bank/institution are these savings held?",
        subtext: "eg. Bank of Ireland, AIB",
        label: "Institution",
        model: "assets",
        rules: [
            {required: true, message: `An institution is required`},
        ],
        hides: (form) => {
            return form.getFieldValue('assetType') !== AssetTypes.SAVING
        },
        target: `applicant${props.applicant}.assets[${props.index}].institution`
    }
}
export function description(props) {
    return {
        name: "description",
        answer: {
            type: "text",
        },
        text: 'Brief description of this investment',
        subtext: "eg. BOI Shares",
        label: "Description",
        model: "assets",
        rules: [
            {required: true, message: `A description is required`},
        ],
        hides: (form) => {
            return form.getFieldValue('assetType') !== AssetTypes.INVESTMENT
        },
        target: `applicant${props.applicant}.assets[${props.index}].description`
    }
}
export function balance(props) {
    return {
        name: "balance",
        answer: {
            type: "euro",
            validation: {
                min: {
                    value: 1,
                    message: 'The minimum value here is €1'
                },
                max: {
                    value: 2000000,
                    message: 'You probably don\'t need a mortgage'
                }
            }
        },
        label: (form) => {
            return form.getFieldValue('assetType') === AssetTypes.INVESTMENT ? "Value" : "Balance"
        },
        text: (form) => {
            return form.getFieldValue('assetType') === AssetTypes.INVESTMENT ? "What is current value of this investment?":"What is the balance in the account?"
        },
        model: "assets",
        hides: (form)=>{
            return ![AssetTypes.INVESTMENT,AssetTypes.SAVING].includes(form.getFieldValue('assetType'))
        },
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 2000000, message: "The maximum value here is €2,000,000"}
        ],
        target: `applicant${props.applicant}.assets[${props.index}].balance`
    }
}
export function increasesFrequency(props) {
    return {
        text: "How often " + props.you.doYou + " save into this account",
        label: "Saving Regularity",
        name: "increasesFrequency",
        answer: {
            type: "select",
            choices: [
                {
                    text: "yearly",
                    value: "YEARLY"
                },
                {
                    text: "quarterly",
                    value: "QUARTERLY"
                },
                {
                    text: "monthly",
                    value: "MONTHLY"
                },
                {
                    text: "fortnightly",
                    value: "FORTNIGHTLY"
                },
                {
                    text: "weekly",
                    value: "WEEKLY"
                },
                {
                    text: "occasionally",
                    value: "OCCASIONALLY"
                },
                {
                    text: "hardly ever",
                    value: "STATIC"
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('assetType') !== AssetTypes.SAVING
        },
        model: "assets",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.assets[${props.index}].increasesFrequency`
    }
}
export function increases(props) {
    return {
        text: "How much would you usually deposit?",
        label: "By how much",
        name: "increases",
        answer: {
            type: "euro",
            validation: {
                min: {value: 1, message: "This must be a value greater than €1"},
                max: {value: 20000000, message: "You probably don't need a mortgage"}
            }
        },
        hides: (form) => {
            if (form.getFieldValue('assetType') !== AssetTypes.SAVING) return true
            return form.getFieldValue('increasesFrequency') === Frequencies.STATIC
        },
        model: "assets",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.assets[${props.index}].increases`
    }
}
export function assetTitle(asset) {
    let title= 'New Asset'
    if (asset.assetType === AssetTypes.INVESTMENT && asset?.description){
        title = capitaliseFirst(asset.description)
    }
    if (asset.assetType === AssetTypes.SAVING && asset?.institution){
        title = capitaliseFirst(asset.institution) + ' Savings'
    }
    return title
}
export function haveAny(props){
    return {
        text: capitaliseFirst(props.you.doYou) + " have any savings accounts or investments?",
        label: "Any Assets",
        name: "hasAssets",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ]
        },
        model: "financial",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.financial.hasAssets`
    }
}

function assets(props) {
    const betterProps = questionProps(props)
    if (props?.index === 'have-any'){
        return [haveAny(betterProps)]
    }
    const questions = [
        assetType(betterProps),
        institution(betterProps),
        description(betterProps),
        balance(betterProps),
        increasesFrequency(betterProps),
        increases(betterProps)
    ]
    return questions
}

export default assets;
