import React from 'react';
function Index({children}) {
    return (
        <div className="max-w-screen-md -mt-16 relative mx-auto">
            <div className="mx-4">
            {children}
            </div>
        </div>
    );
}

export default Index;