import React, {useEffect} from 'react';
import './App.css';
import Screen from "./components/screen";

function App({children, title, navbarColor}) {
    useEffect(() => {
        window.scrollTo(0, 2);
    }, []);

    return (

        <Screen
            title={title}
            xs={22} md={22} lg={20} xl={18}
            navbarColor={navbarColor}
        >
            {children}
        </Screen>

    );
}

export default App;