import React from 'react';
import {LoadingOutlined} from "@ant-design/icons";
import CardFooterButton from "./CardFooterButton";

function FormSubmit(props) {
    return (
        <CardFooterButton
            pop={props.pop}
            detached={!props.submitAsFooter}
            onClick={props.onClick}
            disabled={props.disabled}
            type="primary"
            block
            htmlType="submit">
            {!!props?.buttonText && props.buttonText}
            {!props?.buttonText && 'Save'}

            {props.busy && <LoadingOutlined/>}
        </CardFooterButton>
    )
}

export default FormSubmit;