import {CompassOutlined} from "@ant-design/icons";
import _useColorRange from "../../../assets/elements/_useColorRange";
import Overlay from "../../../assets/elements/Overlay";
import MortgageJourney from "./MortgageJourney";

Index.propTypes = {
    
};

function Index() {
    const colorRange = _useColorRange('#cb8f8f')
    return (
        <>
            <Overlay
                icon={<CompassOutlined/>}
                title={'Mortgage Map'}
                description={'Explore how the mortgage journey works and what to expect at each stage'}
                colorRange={colorRange}
            >

                <MortgageJourney/>
            </Overlay>
           </>
    )
}

export default Index;