import React, {useContext} from 'react';
import {Card} from "antd";
import FormList from "../../../../assets/elements/forms/FormList";
import SnazzyForm from "../../../../assets/elements/forms/SnazzyForm";
import {AuthCheckContext} from "../../../../../../app/auth/AuthCheck";
import useMortgage from "../../../../data-source/hooks/useMortgage";
import {OverlayContext} from "../../../../../../app/components/screen/overlay/CSSOverlayProvider";

function UploadSectionQueries({section, recordComplete}) {
    const {mortgage} = useMortgage()
    const {removeTop} = useContext(OverlayContext)
    const {user} = useContext(AuthCheckContext)
    const initialValues = {}
    section.queries.forEach(q => {
        initialValues[q.id] = (q.userAnswer||[]).join('\n')
    })

    const questions = section.queries.map(q => {
        return {
            ...q,
            label: q.description,
            text: q.query.join(" "),
            name: q.id,
            answer: {
                type: 'textarea'
            },
            rules: [
                {
                    required: true,
                    message: 'Please enter an answer',
                },
            ]
        }
    })

    const handleValid = async (values) => {
        let update = []
        Object.keys(values).forEach(key => {
            update.push({
                target: `queries[${key}]`,
                pairs: {
                    userAnswer: Array.isArray(values[key]) ? values[key] : values[key].split('\n').map(l => l.trim()),
                    userAnswerOn: new Date().toISOString(),
                    userAnswerBy: user.sub,
                    userAnswered: 1,
                    adminAnswered: 0,
                }
            })
        })
        if (recordComplete && recordComplete.hasOwnProperty('queryAnswered')) {
            // check not already answered
            let name = recordComplete.queryAnswered
            // just get the update ... dont do it yet
            update.push( mortgage.setComplete(name, false) )
        }

        await mortgage.mutate({update})
        removeTop()
    }

    return (
        <Card styles={{body:{padding: 0}}}>
            <SnazzyForm
                key={`snazzy-form-${section.name}`}
                handleValid={handleValid}
                initialValues={initialValues}
                popSubmit={true}
            >
                <FormList dataSource={questions}/>
            </SnazzyForm>

        </Card>
    );
}

export default UploadSectionQueries;