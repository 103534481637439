function questionProps(props) {
    const newProps = {
        ...props,
    }
    if (props.applicant){
        newProps.you = props.mortgage[`applicant${props.applicant}`].strings
    }
    return newProps

}

export default questionProps;