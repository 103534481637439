import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AutoSignout from "./auth/AutoSignout";
import ProductDirector from "./ProductDirector";
import AuthApp from "./auth/AuthApp";
import MortgageApp from "../products/mortgage/App";
import {App} from "antd";

function RoutesIndex() {

    return (
        <App>
            <AutoSignout/>
            <Router>
                <Routes>
                    <Route path='/' element={<ProductDirector/>}/>
                    <Route path='/mortgage/:mortgageId/:stage?' element={<MortgageApp/>}/>
                    <Route path='/register/with-google/' element={<AuthApp google/>}/>
                    <Route path='/sign-in' element={<AuthApp signIn/>}/>
                    <Route path='/login' element={<AuthApp signIn/>}/>
                    <Route path='/sign-up/by-invitation/:invitationId' element={<AuthApp signUp invited/>}/>
                    <Route path='/sign-up' element={<AuthApp signUp/>}/>
                    <Route path='/register' element={<AuthApp signUp/>}/>
                </Routes>
            </Router>
        </App>

        // <Router>
        //     <Routes>
        //         <Route path='/' element={<App/>}/>
        //         {/*<Route path='/mortgage/:mortgageId/:segment?/:folder?/:section?/:identifier?' element={<MortgageApplication/>}/>*/}
        //     </Routes>
        // </Router>
    );
}

export default RoutesIndex;

