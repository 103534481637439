import React, {useState} from 'react';

export const DarkModeContext = React.createContext();
function DarkModeProvider({children}) {
    const [darkMode, setDarkMode] = useState(false);
    const toggleDarkMode = () => {
        setDarkMode(s => !s);
    }
    return (
        <DarkModeContext.Provider value={[darkMode,toggleDarkMode]}>
            {children}
        </DarkModeContext.Provider>
    );
}

export default DarkModeProvider;