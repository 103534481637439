import React from 'react';
import Overlay from "../../../assets/elements/Overlay";
import { SettingOutlined} from "@ant-design/icons";
import questionsSetupMortgage from "../../overlay-parts/setup-mortgage/questionsSetupMortgage";
import QuestionsForm from "../../../assets/elements/forms/QuestionsForm";
import QuestionsCard from "../../../assets/elements/forms/QuestionsCard";
import _useColorRange from "../../../assets/elements/_useColorRange";
import useMortgage from "../../../data-source/hooks/useMortgage";

function MortgageSetupOverlay() {
    const {mortgage} = useMortgage()
    const {questions} = questionsSetupMortgage({mortgage})
    const colorRange = _useColorRange('#da9fe1')
    return (
        <>
            <Overlay
                icon={<SettingOutlined/>}
                title={'Setup Mortgage'}
                description={'Describe the mortgage you need'}
                colorRange={colorRange}
            >


                    <QuestionsCard>
                        <QuestionsForm questions={questions} />
                    </QuestionsCard>


            </Overlay>


        </>
    );
}

export default MortgageSetupOverlay;