import React, {useEffect, useRef, useState} from 'react';
import {Hub, urlSafeDecode} from "@aws-amplify/core";
import {API, Auth} from "aws-amplify";
import {Button, Card, Spin, Typography} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
const INVITATIONURL = 'https://nvi7g4wpth5il5rbmwqo43rb3q0tdbox.lambda-url.eu-west-1.on.aws/link'

function GoogleSigned(props) {
    let loc = useLocation()
    const queryParams = new URLSearchParams(loc.search);

    // To get a specific query parameter, use the get method
    const code = queryParams.get('code');
    /**
     * USERS END UP HERE TO HANDLE:
     * 1) Successful Google registration
     * 2) Unsuccessful Google registration (already registered that email with password)
     * 3) Successful Google Login
     * 4) Unsuccessful Login (not registered)
     */

    const [state, setState] = useState({
        screen: !!code ? 'loading' : 'duplicate',
        signingOut: false,
        custom: false

    })
    //localhost:8000/sign-up/by-invitation/d4d3af46-383c-4a08-a578-f02a83f54f4b
    const navigate = useNavigate()

    const logout = async () => {
        setState(s => ({...s, signingOut: true}))
        await Auth.signOut()
    }
    const linkInvitation = async (session,id) => {
        const postData = {
            id
        };
        try {
            const user = await Auth.currentAuthenticatedUser();

            // Get the current session
            const session = await Auth.currentSession();

            // Access the refreshed tokens
            const accessToken = session.getAccessToken().getJwtToken();
            const idToken = session.getIdToken().getJwtToken();
            const refreshToken = session.getRefreshToken().getToken();
            //console.log({accessToken, idToken, refreshToken})
            const result = await API.post('sussdUserAPI','/invitations/link',{
                // headers: {
                //     'Content-Type': 'application/json', // Set the appropriate content type
                //     'Custom-Authorization': idToken
                // },
                body: postData
            })
            //console.log({result})
            //const response = await fetch(INVITATIONURL, init);
            //const responseData = await response.json();


        } catch (error) {
console.log(error)
        }
    }
    const handleCustomStateCapture = async (data) => {
        const customState = JSON.parse(data)
        //console.log({customState})
        const session = await Auth.currentSession();
        const payload = session && session.getIdToken().payload
        // It could be a user login
        //console.log({payload})
        if (payload?.applicantId) {
            return navigate('/')
        } else if (customState?.invitation) {
            //console.log('LINKING INVITATION')
            await linkInvitation(session, customState.invitation)
            try {
                // const user = await Auth.currentAuthenticatedUser();
                // const updatedUser = await Auth.updateUserAttributes(user, {
                //     'custom:invitationId': customState.invitation,
                // });
                // console.log('User attributes updated:', updatedUser)
            } catch (error) {
                console.error('Error updating user attributes:', error);
            }
        }
    }

    useEffect(() => {
        const listener = Hub.listen('auth', (response) => {
            // will only get here is if there is a code in the query params (user doesn't already exist)
            const event = response.payload.event;
            switch (event) {
                case 'signOut':
                    navigate('/sign-in')
                    break;
                case 'signIn':
                    break;
                case 'customOAuthState':
                    handleCustomStateCapture(response.payload.data)
                    break;
            }

        });
        return () => {
            listener()
        }
    }, [])
    return (
        <Card bordered={false} style={{position: 'relative', marginTop: 100}}>

            <div style={{position: 'relative'}}>
                {state.screen === 'loading' && (
                    <div style={{
                        marginTop: 100,
                        marginBottom: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Spin size={"large"}/>
                        <Typography.Title level={5}>Verifying</Typography.Title>
                    </div>
                )}
                {state.screen === 'duplicate' && (
                    <div style={{
                        marginTop: 100,
                        marginBottom: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Typography.Title level={5}>Oopsie Daisy!</Typography.Title>
                        <p>It seems you have already registered this email on another account. Sign in to that account
                            using your email and password.</p>

                        <Button onClick={logout}>
                            Return to Sign In
                            {state.signingOut && <Spin size="small" style={{marginLeft: 3}}/>}
                        </Button>
                    </div>
                )}
            </div>

        </Card>
    );
}

export default GoogleSigned;