import {
    applicationTypePurchase,
    applicationTypeRefinance, twoApplicants
} from "../apply/mortgage/setupProfile";
import {
    mortgageType,
    berScore,
    buildType,
    depositSavings,
    firstHomeScheme,
    giftAmount,
    helpToBuyScheme,
    loanRequired,
    loanTerm, propertyType,
    propertyValueRefinance,
    propertyValuePurchase,
    receivingGifts
} from "../apply/mortgage/setupGoal";


import questionProps from "../apply/questionProps";
import {addressKnown} from "../apply/mortgage/property";

function questionsSetupMortgage({mortgage}) {

    const betterProps = questionProps({mortgage})

    const propertyProps = {...betterProps, index: mortgage.properties[0].id}

    const propertyTypePurchase = {...propertyType(propertyProps)}
    propertyTypePurchase.hides = (form) => {
        return form.getFieldValue('mortgageType') !== 'PURCHASE' || form.getFieldValue('addressKnown') !== true
    }
    const propertyTypeRefinance = {...propertyType(propertyProps)}
    propertyTypeRefinance.hides = (form) => {
        return form.getFieldValue('mortgageType') !== 'REFINANCE'
    }
    propertyTypeRefinance.text = "What type of property do you have?"

    const questions = [
        mortgageType(betterProps),
        applicationTypePurchase(betterProps),
        applicationTypeRefinance(betterProps),
        addressKnown(propertyProps),
        propertyTypeRefinance,
        propertyTypePurchase,
        buildType(propertyProps),
        berScore(propertyProps),
        propertyValueRefinance(propertyProps),
        propertyValuePurchase(propertyProps),
        loanRequired(betterProps),
        loanTerm(betterProps),
        helpToBuyScheme(betterProps),
        firstHomeScheme(betterProps),
        receivingGifts(betterProps),
        giftAmount(betterProps),
        depositSavings(betterProps),
        twoApplicants(betterProps)
    ]

    return {
        questions
    }
}

export default questionsSetupMortgage;