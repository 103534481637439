import React from 'react';
import {Badge, Tag} from "antd";

function CardStatus({children, dot}) {
    const textClass = dot && dot === 'success' ? 'light-green-500' : '';

    return (
        <Tag>
            {
                !!dot && (
                    <Badge  status={dot} />
                )
            }

            <span className={`text-xs text-gray-400 ${textClass}`}> {children} </span>
        </Tag>
    );
}

export default CardStatus;