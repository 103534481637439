import questionProps from "../questionProps";
import {EmploymentTypes} from "../../../../../../../models";
import {capitaliseFirst} from "../../../../assets/functions/functions";
export function employmentType(props) {
    return {
        label: "Employment Status",
        text: "What is " + props.you.your + " current employment status?",
        name: "employmentType",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Employed",
                    value: "EMPLOYED"
                },
                {
                    text: "Self-Employed",
                    value: "SELFEMPLOYED"
                },
                {
                    text: "Homemaker",
                    value: "HOMEMAKER"
                },
                {
                    text: "Student",
                    value: "STUDENT"
                }
            ]
        },
        model: "employment",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].employmentType`,
        saveOnChange: true
    }
}
export function employmentBasis(props) {
    return {
        text: "On what basis " + props.you.areYou + " employed?",
        label: "Employment Basis",
        name: "employmentBasis",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Full-time",
                    value: "FULLTIME"
                },
                {
                    text: "Contract Basis",
                    value: "CONTRACT"

                },
                {
                    text: "Temporary Full-time",
                    value: "TEMPFULLTIME"
                },
                {
                    text: "Part-time",
                    value: "PARTTIME"
                },
                {
                    text: "Temporary Part-time",
                    value: "TEMPPARTTIME"
                },
            ]
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].employmentBasis`
    }
}
export function positionHeld(props) {
    return {
        name: "positionHeld",
        label: "Position",
        text: "What is " + props.you.your + " position there?",
        answer: {
            type: "text"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: "A job title is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].positionHeld`
    }
}
export function limitedCompany(props) {
    return {
        text: "Is " + props.you.your + " business a limited company?",
        label: "Limited Company",
        name: "limitedCompany",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].limitedCompany`
    }
}
export function isDirector(props) {
    return {
        text: capitaliseFirst(props.you.areYou) + " a director or shareholder in the company?",
        label: "Director/Shareholder",
        name: "isDirector",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED){
                return true
            }
            return form.getFieldValue('limitedCompany') === false;
        },
        model: "employment",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].isDirector`
    }
}
export function percentageOfShares(props) {
    return {
        label: "Percentage Of Shares",
        text: "What percentage of shares " + props.you.doYou + " hold in the company?",
        name: "percentageOfShares",
        answer: {
            type: "float"
        },
        hides: (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED){
                return true
            }
            if (form.getFieldValue('limitedCompany') === false){
                return true
            }
            return form.getFieldValue('isDirector') === false;
        },
        model: "employment",
        rules: [
            {required: true, message: `A percentage is required`},
            {type: 'number', min: 1, message: 'This must be a value greater than zero'},
            {type: 'number', max: 100, message: 'This must be a value maximum 100'},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].percentageOfShares`
    }
}
function employment(props) {
    const betterProps = questionProps(props)
    const questions = [
        employmentType(betterProps),
        employmentBasis(betterProps),
        positionHeld(betterProps),
        limitedCompany(betterProps),
        isDirector(betterProps),
        percentageOfShares(betterProps),
    ]
    if (!props.index){
        return questions.map(q => {
            q.target = q.target.replace(`employmentIncome[undefined]`, 'employment')
            return q
        })
    }

    return questions
}

export default employment;