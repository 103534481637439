import React, { useRef} from 'react';
import {Button, Col, ConfigProvider, Row, Typography} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import logo from "../images/logo-white.svg";
import reviews from "../images/google-review.svg";
import img from "../images/registerImg.png";
import Justifier from "../components/screen/Justifier";
import RegisterPage from "./RegisterPage";
import SignInPage from "./SignInPage";
import GoogleSigned from "./GoogleSigned";
import {useParams} from "react-router-dom";


function AuthApp(props) {
    const splashColor = '#147180'
    const navbarButtonStyle = {
        padding: '4px 8px',
        borderRadius: 4
    }
    const imgStyle = {
        width:'100%',
        maxWidth: '520px',
        position: 'relative',
    }
    const imgRef = useRef(null)
    const params = useParams()
    function CallToAction(){
        if (props?.signUp){
            if (params?.invitationId){
                return <>
                <Typography.Title style={{color: 'white'}}>Join a Mortgage Application</Typography.Title>
                    <Typography.Title style={{color: '#145777'}} level={5}>Register to join a
                        mortgage application and expedite the mortgage process    </Typography.Title>

                </>
            }
            return <>
                <Typography.Title style={{color: 'white'}}>Sign up to Kick-Start your Mortgage
                today!</Typography.Title>
            <Typography.Title style={{color: '#145777'}} level={5}>Register to receive your free
                mortgage recommendation or start a mortgage application </Typography.Title>
            </>
        }
        return <>
            <Typography.Title style={{color: 'white'}}>Sign in to Kick-Start your Mortgage
                today!</Typography.Title>
            <Typography.Title style={{color: '#145777'}} level={5}> </Typography.Title>
        </>

    }

    function Forms(){
        const myStyle = {
            paddingTop:0

        }
        return <div style={myStyle}>
            {!!props?.signUp && <RegisterPage invitationId={params?.invitationId}/>}
            {!!props?.google && <GoogleSigned/>}
            {!!props.signIn && <SignInPage/>}
        </div>
    }
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#22acc6',
                    warningColor: '#f68f33',
                    colorInfo: '#74b6df',
                    colorWarningBg: '#f68f33'
                }
            }}
        >

            <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#84d6da'}}>

                {/*  NAVBAR  */}
                <div style={{position: 'sticky', top: 0, left: 0, right: 0, zIndex: 4}}>
                    <div style={{backgroundColor: splashColor}}>
                        <Justifier>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                gap: 16
                            }}>
                                <a href="https://www.sussd.ie">
                                    <Button style={navbarButtonStyle}>
                                        <HomeOutlined/>
                                    </Button>
                                </a>
                                <img alt="Company Logo" src={logo}
                                     style={{height: '42px', margin: '7px auto 16px 0px'}}/>
                            </div>
                        </Justifier>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" height="50" width="100%" preserveAspectRatio="none"
                         viewBox="0 0 407 79.468" style={{position: 'relative', transform: 'scaleX(-1)'}}>
                        <g id="primary-curve" transform="translate(0 0)">
                            <path id="Path_1" data-name="Path 1"
                                  d="M-69.986-169.67h407V-90.2s-89.355-60.605-219.321-72.431C-11.79-174.415-69.986-133.67-69.986-133.67Z"
                                  transform="translate(69.986 169.67)" fill={splashColor}/>
                        </g>
                    </svg>
                </div>

                {/*  JUSTIFY   */}
                <Justifier style={{width: '100%', zIndex: 2}}>


                        {/*  CONTENT  */}
                        <Row justify="center">
                            <Col xs={24} lg={12}>
                                <CallToAction/>
                                <img ref={imgRef} style={imgStyle} src={img} alt="" className='grass-img mx-auto'/>
                            </Col>
                            <Col xs={24} lg={12}>
                            </Col>
                        </Row>

                </Justifier>
                <div className="grass" style={{display:'flex', flex:1}}>
                    <Justifier style={{width: '100%', zIndex: 2}}>
                        <Row justify="center">
                            <Col xs={24} lg={12}>
                            </Col>
                            <Col xs={24} lg={12}>
                                <div className="grass-right-col">
                                    <Forms/>
                                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 24}}>
                                        <a href="https://www.sussd.ie"><img src={reviews} style={{width: 210}} alt={""}/></a>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Justifier>
                </div>
            </div>
        </ConfigProvider>
    )
}

export default AuthApp;