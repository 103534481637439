import React from 'react';
import {Form, List} from "antd";
import FormItemQuestion from "./FormItemQuestion";

function FormList({dataSource}) {


    return (
        <List dataSource={dataSource}
              renderItem={(question, index) => {
                  if (!!question?.hides || typeof question.text === "function" || typeof question.label === "function" || typeof question.rules === "function") {
                      return <Form.Item
                          noStyle
                          shouldUpdate={true}>
                          {(formInstance) => {
                              if (question?.hides) {
                                  if (question.hides(formInstance)) {
                                      return null
                                  }
                              }
                              return <List.Item>

                                  <FormItemQuestion question={question}/>
                              </List.Item>
                          }}
                      </Form.Item>
                  }
                  return <List.Item>

                      <FormItemQuestion question={question}/>
                  </List.Item>
              }}/>
    );
}

export default FormList;