import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Form, Input, Modal, Spin, Typography} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, LockOutlined, MailOutlined} from "@ant-design/icons";
import {Auth} from 'aws-amplify';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {Hub} from "@aws-amplify/core";
import {Link, useNavigate} from "react-router-dom";

const dialCodes = [
    {
        "dial": "+1",
        "flag": "🇨🇦",
        "code": "CA"
    },
    {
        "dial": "+1",
        "flag": "🇺🇸",
        "code": "US"
    },
    {
        "dial": "+1242",
        "flag": "🇧🇸",
        "code": "BS"
    },
    {
        "dial": "+1246",
        "flag": "🇧🇧",
        "code": "BB"
    },
    {
        "dial": "+1264",
        "flag": "🇦🇮",
        "code": "AI"
    },
    {
        "dial": "+1268",
        "flag": "🇦🇬",
        "code": "AG"
    },
    {
        "dial": "+1284",
        "flag": "🇻🇬",
        "code": "VG"
    },
    {
        "dial": "+1340",
        "flag": "🇻🇮",
        "code": "VI"
    },
    {
        "dial": "+1441",
        "flag": "🇧🇲",
        "code": "BM"
    },
    {
        "dial": "+1473",
        "flag": "🇬🇩",
        "code": "GD"
    },
    {
        "dial": "+1649",
        "flag": "🇹🇨",
        "code": "TC"
    },
    {
        "dial": "+1664",
        "flag": "🇲🇸",
        "code": "MS"
    },
    {
        "dial": "+1670",
        "flag": "🇲🇵",
        "code": "MP"
    },
    {
        "dial": "+1671",
        "flag": "🇬🇺",
        "code": "GU"
    },
    {
        "dial": "+1684",
        "flag": "🇦🇸",
        "code": "AS"
    },
    {
        "dial": "+1758",
        "flag": "🇱🇨",
        "code": "LC"
    },
    {
        "dial": "+1767",
        "flag": "🇩🇲",
        "code": "DM"
    },
    {
        "dial": "+1784",
        "flag": "🇻🇨",
        "code": "VC"
    },
    {
        "dial": "+1849",
        "flag": "🇩🇴",
        "code": "DO"
    },
    {
        "dial": "+1868",
        "flag": "🇹🇹",
        "code": "TT"
    },
    {
        "dial": "+1869",
        "flag": "🇰🇳",
        "code": "KN"
    },
    {
        "dial": "+1876",
        "flag": "🇯🇲",
        "code": "JM"
    },
    {
        "dial": "+1939",
        "flag": "🇵🇷",
        "code": "PR"
    },
    {
        "dial": "+20",
        "flag": "🇪🇬",
        "code": "EG"
    },
    {
        "dial": "+211",
        "flag": "🇸🇸",
        "code": "SS"
    },
    {
        "dial": "+212",
        "flag": "🇲🇦",
        "code": "MA"
    },
    {
        "dial": "+213",
        "flag": "🇩🇿",
        "code": "DZ"
    },
    {
        "dial": "+216",
        "flag": "🇹🇳",
        "code": "TN"
    },
    {
        "dial": "+218",
        "flag": "🇱🇾",
        "code": "LY"
    },
    {
        "dial": "+220",
        "flag": "🇬🇲",
        "code": "GM"
    },
    {
        "dial": "+221",
        "flag": "🇸🇳",
        "code": "SN"
    },
    {
        "dial": "+222",
        "flag": "🇲🇷",
        "code": "MR"
    },
    {
        "dial": "+223",
        "flag": "🇲🇱",
        "code": "ML"
    },
    {
        "dial": "+224",
        "flag": "🇬🇳",
        "code": "GN"
    },
    {
        "dial": "+225",
        "flag": "🇨🇮",
        "code": "CI"
    },
    {
        "dial": "+226",
        "flag": "🇧🇫",
        "code": "BF"
    },
    {
        "dial": "+227",
        "flag": "🇳🇪",
        "code": "NE"
    },
    {
        "dial": "+228",
        "flag": "🇹🇬",
        "code": "TG"
    },
    {
        "dial": "+229",
        "flag": "🇧🇯",
        "code": "BJ"
    },
    {
        "dial": "+230",
        "flag": "🇲🇺",
        "code": "MU"
    },
    {
        "dial": "+231",
        "flag": "🇱🇷",
        "code": "LR"
    },
    {
        "dial": "+232",
        "flag": "🇸🇱",
        "code": "SL"
    },
    {
        "dial": "+233",
        "flag": "🇬🇭",
        "code": "GH"
    },
    {
        "dial": "+234",
        "flag": "🇳🇬",
        "code": "NG"
    },
    {
        "dial": "+235",
        "flag": "🇹🇩",
        "code": "TD"
    },
    {
        "dial": "+236",
        "flag": "🇨🇫",
        "code": "CF"
    },
    {
        "dial": "+237",
        "flag": "🇨🇲",
        "code": "CM"
    },
    {
        "dial": "+238",
        "flag": "🇨🇻",
        "code": "CV"
    },
    {
        "dial": "+239",
        "flag": "🇸🇹",
        "code": "ST"
    },
    {
        "dial": "+240",
        "flag": "🇬🇶",
        "code": "GQ"
    },
    {
        "dial": "+241",
        "flag": "🇬🇦",
        "code": "GA"
    },
    {
        "dial": "+242",
        "flag": "🇨🇬",
        "code": "CG"
    },
    {
        "dial": "+243",
        "flag": "🇨🇩",
        "code": "CD"
    },
    {
        "dial": "+244",
        "flag": "🇦🇴",
        "code": "AO"
    },
    {
        "dial": "+245",
        "flag": "🇬🇼",
        "code": "GW"
    },
    {
        "dial": "+246",
        "flag": "🇮🇴",
        "code": "IO"
    },
    {
        "dial": "+248",
        "flag": "🇸🇨",
        "code": "SC"
    },
    {
        "dial": "+249",
        "flag": "🇸🇩",
        "code": "SD"
    },
    {
        "dial": "+250",
        "flag": "🇷🇼",
        "code": "RW"
    },
    {
        "dial": "+251",
        "flag": "🇪🇹",
        "code": "ET"
    },
    {
        "dial": "+252",
        "flag": "🇸🇴",
        "code": "SO"
    },
    {
        "dial": "+253",
        "flag": "🇩🇯",
        "code": "DJ"
    },
    {
        "dial": "+254",
        "flag": "🇰🇪",
        "code": "KE"
    },
    {
        "dial": "+255",
        "flag": "🇹🇿",
        "code": "TZ"
    },
    {
        "dial": "+256",
        "flag": "🇺🇬",
        "code": "UG"
    },
    {
        "dial": "+257",
        "flag": "🇧🇮",
        "code": "BI"
    },
    {
        "dial": "+258",
        "flag": "🇲🇿",
        "code": "MZ"
    },
    {
        "dial": "+260",
        "flag": "🇿🇲",
        "code": "ZM"
    },
    {
        "dial": "+261",
        "flag": "🇲🇬",
        "code": "MG"
    },
    {
        "dial": "+262",
        "flag": "🇹🇫",
        "code": "TF"
    },
    {
        "dial": "+262",
        "flag": "🇾🇹",
        "code": "YT"
    },
    {
        "dial": "+262",
        "flag": "🇷🇪",
        "code": "RE"
    },
    {
        "dial": "+263",
        "flag": "🇿🇼",
        "code": "ZW"
    },
    {
        "dial": "+264",
        "flag": "🇳🇦",
        "code": "NA"
    },
    {
        "dial": "+265",
        "flag": "🇲🇼",
        "code": "MW"
    },
    {
        "dial": "+266",
        "flag": "🇱🇸",
        "code": "LS"
    },
    {
        "dial": "+267",
        "flag": "🇧🇼",
        "code": "BW"
    },
    {
        "dial": "+268",
        "flag": "🇸🇿",
        "code": "SZ"
    },
    {
        "dial": "+269",
        "flag": "🇰🇲",
        "code": "KM"
    },
    {
        "dial": "+27",
        "flag": "🇿🇦",
        "code": "ZA"
    },
    {
        "dial": "+290",
        "flag": "🇸🇭",
        "code": "SH"
    },
    {
        "dial": "+291",
        "flag": "🇪🇷",
        "code": "ER"
    },
    {
        "dial": "+297",
        "flag": "🇦🇼",
        "code": "AW"
    },
    {
        "dial": "+298",
        "flag": "🇫🇴",
        "code": "FO"
    },
    {
        "dial": "+299",
        "flag": "🇬🇱",
        "code": "GL"
    },
    {
        "dial": "+30",
        "flag": "🇬🇷",
        "code": "GR"
    },
    {
        "dial": "+31",
        "flag": "🇳🇱",
        "code": "NL"
    },
    {
        "dial": "+32",
        "flag": "🇧🇪",
        "code": "BE"
    },
    {
        "dial": "+33",
        "flag": "🇫🇷",
        "code": "FR"
    },
    {
        "dial": "+34",
        "flag": "🇪🇸",
        "code": "ES"
    },
    {
        "dial": "+345",
        "flag": "🇰🇾",
        "code": "KY"
    },
    {
        "dial": "+350",
        "flag": "🇬🇮",
        "code": "GI"
    },
    {
        "dial": "+351",
        "flag": "🇵🇹",
        "code": "PT"
    },
    {
        "dial": "+352",
        "flag": "🇱🇺",
        "code": "LU"
    },
    {
        "dial": "+353",
        "flag": "🇮🇪",
        "code": "IE"
    },
    {
        "dial": "+354",
        "flag": "🇮🇸",
        "code": "IS"
    },
    {
        "dial": "+355",
        "flag": "🇦🇱",
        "code": "AL"
    },
    {
        "dial": "+356",
        "flag": "🇲🇹",
        "code": "MT"
    },
    {
        "dial": "+357",
        "flag": "🇨🇾",
        "code": "CY"
    },
    {
        "dial": "+358",
        "flag": "🇦🇽",
        "code": "AX"
    },
    {
        "dial": "+358",
        "flag": "🇫🇮",
        "code": "FI"
    },
    {
        "dial": "+359",
        "flag": "🇧🇬",
        "code": "BG"
    },
    {
        "dial": "+36",
        "flag": "🇭🇺",
        "code": "HU"
    },
    {
        "dial": "+370",
        "flag": "🇱🇹",
        "code": "LT"
    },
    {
        "dial": "+371",
        "flag": "🇱🇻",
        "code": "LV"
    },
    {
        "dial": "+372",
        "flag": "🇪🇪",
        "code": "EE"
    },
    {
        "dial": "+373",
        "flag": "🇲🇩",
        "code": "MD"
    },
    {
        "dial": "+374",
        "flag": "🇦🇲",
        "code": "AM"
    },
    {
        "dial": "+375",
        "flag": "🇧🇾",
        "code": "BY"
    },
    {
        "dial": "+376",
        "flag": "🇦🇩",
        "code": "AD"
    },
    {
        "dial": "+377",
        "flag": "🇲🇨",
        "code": "MC"
    },
    {
        "dial": "+378",
        "flag": "🇸🇲",
        "code": "SM"
    },
    {
        "dial": "+379",
        "flag": "🇻🇦",
        "code": "VA"
    },
    {
        "dial": "+380",
        "flag": "🇺🇦",
        "code": "UA"
    },
    {
        "dial": "+381",
        "flag": "🇷🇸",
        "code": "RS"
    },
    {
        "dial": "+382",
        "flag": "🇲🇪",
        "code": "ME"
    },
    {
        "dial": "+383",
        "flag": "🇽🇰",
        "code": "XK"
    },
    {
        "dial": "+385",
        "flag": "🇭🇷",
        "code": "HR"
    },
    {
        "dial": "+386",
        "flag": "🇸🇮",
        "code": "SI"
    },
    {
        "dial": "+387",
        "flag": "🇧🇦",
        "code": "BA"
    },
    {
        "dial": "+389",
        "flag": "🇲🇰",
        "code": "MK"
    },
    {
        "dial": "+39",
        "flag": "🇮🇹",
        "code": "IT"
    },
    {
        "dial": "+40",
        "flag": "🇷🇴",
        "code": "RO"
    },
    {
        "dial": "+41",
        "flag": "🇨🇭",
        "code": "CH"
    },
    {
        "dial": "+420",
        "flag": "🇨🇿",
        "code": "CZ"
    },
    {
        "dial": "+421",
        "flag": "🇸🇰",
        "code": "SK"
    },
    {
        "dial": "+423",
        "flag": "🇱🇮",
        "code": "LI"
    },
    {
        "dial": "+43",
        "flag": "🇦🇹",
        "code": "AT"
    },
    {
        "dial": "+44",
        "flag": "🇬🇬",
        "code": "GG"
    },
    {
        "dial": "+44",
        "flag": "🇮🇲",
        "code": "IM"
    },
    {
        "dial": "+44",
        "flag": "🇯🇪",
        "code": "JE"
    },
    {
        "dial": "+44",
        "flag": "🇬🇧",
        "code": "GB"
    },
    {
        "dial": "+45",
        "flag": "🇩🇰",
        "code": "DK"
    },
    {
        "dial": "+46",
        "flag": "🇸🇪",
        "code": "SE"
    },
    {
        "dial": "+47",
        "flag": "🇧🇻",
        "code": "BV"
    },
    {
        "dial": "+47",
        "flag": "🇳🇴",
        "code": "NO"
    },
    {
        "dial": "+47",
        "flag": "🇸🇯",
        "code": "SJ"
    },
    {
        "dial": "+48",
        "flag": "🇵🇱",
        "code": "PL"
    },
    {
        "dial": "+49",
        "flag": "🇩🇪",
        "code": "DE"
    },
    {
        "dial": "+500",
        "flag": "🇫🇰",
        "code": "FK"
    },
    {
        "dial": "+500",
        "flag": "🇬🇸",
        "code": "GS"
    },
    {
        "dial": "+501",
        "flag": "🇧🇿",
        "code": "BZ"
    },
    {
        "dial": "+502",
        "flag": "🇬🇹",
        "code": "GT"
    },
    {
        "dial": "+503",
        "flag": "🇸🇻",
        "code": "SV"
    },
    {
        "dial": "+504",
        "flag": "🇭🇳",
        "code": "HN"
    },
    {
        "dial": "+505",
        "flag": "🇳🇮",
        "code": "NI"
    },
    {
        "dial": "+506",
        "flag": "🇨🇷",
        "code": "CR"
    },
    {
        "dial": "+507",
        "flag": "🇵🇦",
        "code": "PA"
    },
    {
        "dial": "+508",
        "flag": "🇵🇲",
        "code": "PM"
    },
    {
        "dial": "+509",
        "flag": "🇭🇹",
        "code": "HT"
    },
    {
        "dial": "+51",
        "flag": "🇵🇪",
        "code": "PE"
    },
    {
        "dial": "+52",
        "flag": "🇲🇽",
        "code": "MX"
    },
    {
        "dial": "+53",
        "flag": "🇨🇺",
        "code": "CU"
    },
    {
        "dial": "+54",
        "flag": "🇦🇷",
        "code": "AR"
    },
    {
        "dial": "+55",
        "flag": "🇧🇷",
        "code": "BR"
    },
    {
        "dial": "+56",
        "flag": "🇨🇱",
        "code": "CL"
    },
    {
        "dial": "+57",
        "flag": "🇨🇴",
        "code": "CO"
    },
    {
        "dial": "+58",
        "flag": "🇻🇪",
        "code": "VE"
    },
    {
        "dial": "+590",
        "flag": "🇬🇵",
        "code": "GP"
    },
    {
        "dial": "+590",
        "flag": "🇧🇱",
        "code": "BL"
    },
    {
        "dial": "+590",
        "flag": "🇲🇫",
        "code": "MF"
    },
    {
        "dial": "+591",
        "flag": "🇧🇴",
        "code": "BO"
    },
    {
        "dial": "+592",
        "flag": "🇬🇾",
        "code": "GY"
    },
    {
        "dial": "+593",
        "flag": "🇪🇨",
        "code": "EC"
    },
    {
        "dial": "+594",
        "flag": "🇬🇫",
        "code": "GF"
    },
    {
        "dial": "+595",
        "flag": "🇵🇾",
        "code": "PY"
    },
    {
        "dial": "+596",
        "flag": "🇲🇶",
        "code": "MQ"
    },
    {
        "dial": "+597",
        "flag": "🇸🇷",
        "code": "SR"
    },
    {
        "dial": "+598",
        "flag": "🇺🇾",
        "code": "UY"
    },
    {
        "dial": "+599",
        "flag": "",
        "code": "AN"
    },
    {
        "dial": "+60",
        "flag": "🇲🇾",
        "code": "MY"
    },
    {
        "dial": "+61",
        "flag": "🇦🇺",
        "code": "AU"
    },
    {
        "dial": "+61",
        "flag": "🇨🇽",
        "code": "CX"
    },
    {
        "dial": "+61",
        "flag": "🇨🇨",
        "code": "CC"
    },
    {
        "dial": "+62",
        "flag": "🇮🇩",
        "code": "ID"
    },
    {
        "dial": "+63",
        "flag": "🇵🇭",
        "code": "PH"
    },
    {
        "dial": "+64",
        "flag": "🇳🇿",
        "code": "NZ"
    },
    {
        "dial": "+64",
        "flag": "🇵🇳",
        "code": "PN"
    },
    {
        "dial": "+65",
        "flag": "🇸🇬",
        "code": "SG"
    },
    {
        "dial": "+66",
        "flag": "🇹🇭",
        "code": "TH"
    },
    {
        "dial": "+670",
        "flag": "🇹🇱",
        "code": "TL"
    },
    {
        "dial": "+672",
        "flag": "🇦🇶",
        "code": "AQ"
    },
    {
        "dial": "+672",
        "flag": "🇭🇲",
        "code": "HM"
    },
    {
        "dial": "+672",
        "flag": "🇳🇫",
        "code": "NF"
    },
    {
        "dial": "+673",
        "flag": "🇧🇳",
        "code": "BN"
    },
    {
        "dial": "+674",
        "flag": "🇳🇷",
        "code": "NR"
    },
    {
        "dial": "+675",
        "flag": "🇵🇬",
        "code": "PG"
    },
    {
        "dial": "+676",
        "flag": "🇹🇴",
        "code": "TO"
    },
    {
        "dial": "+677",
        "flag": "🇸🇧",
        "code": "SB"
    },
    {
        "dial": "+678",
        "flag": "🇻🇺",
        "code": "VU"
    },
    {
        "dial": "+679",
        "flag": "🇫🇯",
        "code": "FJ"
    },
    {
        "dial": "+680",
        "flag": "🇵🇼",
        "code": "PW"
    },
    {
        "dial": "+681",
        "flag": "🇼🇫",
        "code": "WF"
    },
    {
        "dial": "+682",
        "flag": "🇨🇰",
        "code": "CK"
    },
    {
        "dial": "+683",
        "flag": "🇳🇺",
        "code": "NU"
    },
    {
        "dial": "+685",
        "flag": "🇼🇸",
        "code": "WS"
    },
    {
        "dial": "+686",
        "flag": "🇰🇮",
        "code": "KI"
    },
    {
        "dial": "+687",
        "flag": "🇳🇨",
        "code": "NC"
    },
    {
        "dial": "+688",
        "flag": "🇹🇻",
        "code": "TV"
    },
    {
        "dial": "+689",
        "flag": "🇵🇫",
        "code": "PF"
    },
    {
        "dial": "+690",
        "flag": "🇹🇰",
        "code": "TK"
    },
    {
        "dial": "+691",
        "flag": "🇫🇲",
        "code": "FM"
    },
    {
        "dial": "+692",
        "flag": "🇲🇭",
        "code": "MH"
    },
    {
        "dial": "+7",
        "flag": "🇰🇿",
        "code": "KZ"
    },
    {
        "dial": "+7",
        "flag": "🇷🇺",
        "code": "RU"
    },
    {
        "dial": "+81",
        "flag": "🇯🇵",
        "code": "JP"
    },
    {
        "dial": "+82",
        "flag": "🇰🇷",
        "code": "KR"
    },
    {
        "dial": "+84",
        "flag": "🇻🇳",
        "code": "VN"
    },
    {
        "dial": "+850",
        "flag": "🇰🇵",
        "code": "KP"
    },
    {
        "dial": "+852",
        "flag": "🇭🇰",
        "code": "HK"
    },
    {
        "dial": "+853",
        "flag": "🇲🇴",
        "code": "MO"
    },
    {
        "dial": "+855",
        "flag": "🇰🇭",
        "code": "KH"
    },
    {
        "dial": "+856",
        "flag": "🇱🇦",
        "code": "LA"
    },
    {
        "dial": "+86",
        "flag": "🇨🇳",
        "code": "CN"
    },
    {
        "dial": "+880",
        "flag": "🇧🇩",
        "code": "BD"
    },
    {
        "dial": "+886",
        "flag": "🇹🇼",
        "code": "TW"
    },
    {
        "dial": "+90",
        "flag": "🇹🇷",
        "code": "TR"
    },
    {
        "dial": "+91",
        "flag": "🇮🇳",
        "code": "IN"
    },
    {
        "dial": "+92",
        "flag": "🇵🇰",
        "code": "PK"
    },
    {
        "dial": "+93",
        "flag": "🇦🇫",
        "code": "AF"
    },
    {
        "dial": "+94",
        "flag": "🇱🇰",
        "code": "LK"
    },
    {
        "dial": "+95",
        "flag": "🇲🇲",
        "code": "MM"
    },
    {
        "dial": "+960",
        "flag": "🇲🇻",
        "code": "MV"
    },
    {
        "dial": "+961",
        "flag": "🇱🇧",
        "code": "LB"
    },
    {
        "dial": "+962",
        "flag": "🇯🇴",
        "code": "JO"
    },
    {
        "dial": "+963",
        "flag": "🇸🇾",
        "code": "SY"
    },
    {
        "dial": "+964",
        "flag": "🇮🇶",
        "code": "IQ"
    },
    {
        "dial": "+965",
        "flag": "🇰🇼",
        "code": "KW"
    },
    {
        "dial": "+966",
        "flag": "🇸🇦",
        "code": "SA"
    },
    {
        "dial": "+967",
        "flag": "🇾🇪",
        "code": "YE"
    },
    {
        "dial": "+968",
        "flag": "🇴🇲",
        "code": "OM"
    },
    {
        "dial": "+970",
        "flag": "🇵🇸",
        "code": "PS"
    },
    {
        "dial": "+971",
        "flag": "🇦🇪",
        "code": "AE"
    },
    {
        "dial": "+972",
        "flag": "🇮🇱",
        "code": "IL"
    },
    {
        "dial": "+973",
        "flag": "🇧🇭",
        "code": "BH"
    },
    {
        "dial": "+974",
        "flag": "🇶🇦",
        "code": "QA"
    },
    {
        "dial": "+975",
        "flag": "🇧🇹",
        "code": "BT"
    },
    {
        "dial": "+976",
        "flag": "🇲🇳",
        "code": "MN"
    },
    {
        "dial": "+977",
        "flag": "🇳🇵",
        "code": "NP"
    },
    {
        "dial": "+98",
        "flag": "🇮🇷",
        "code": "IR"
    },
    {
        "dial": "+992",
        "flag": "🇹🇯",
        "code": "TJ"
    },
    {
        "dial": "+993",
        "flag": "🇹🇲",
        "code": "TM"
    },
    {
        "dial": "+994",
        "flag": "🇦🇿",
        "code": "AZ"
    },
    {
        "dial": "+995",
        "flag": "🇬🇪",
        "code": "GE"
    },
    {
        "dial": "+996",
        "flag": "🇰🇬",
        "code": "KG"
    },
    {
        "dial": "+998",
        "flag": "🇺🇿",
        "code": "UZ"
    }
]
dialCodes.sort((a, b) => b.dial.localeCompare(a.dial)); // Using localeCompare to reverse order

async function confirmSignUp({username, code}) {
    return await Auth.confirmSignUp(username, code)
}
async function resendConfirmationCode({username}) {
    return await Auth.resendSignUp(username);
}
async function signUp({email, password, phone_number, given_name}) {
    const response = await Auth.signUp({
        username: email,
        password,
        attributes: {
            email,
            phone_number,
            given_name,
            'custom:app2': 'true'
        },
        autoSignIn: {
            enabled: true,
        }
    });
    return response
}
async function signIn({username, password}) {
    return await Auth.signIn(username, password);
}

function SignInPage(props) {
    const credRef = useRef(null)
    const [confirmationForm] = Form.useForm()
    const [signInForm] = Form.useForm()
    const [forgotForm] = Form.useForm()
    const [invalidCode, setInvalidCode] = useState('');
    const [state, setState] = useState({
        busy: false,
        screen: 'signIn', // 'newPassword', 'forgotPassword', 'completeConfirmation'
        badEmail: false,
        badPassword: false,
        signInError: false,
        confirmingText: 'Confirming',
        tempEmail: null,
        tempPassword: null,
    })
    const navigate = useNavigate()
    const validators = {
        password: (_, password) => {
            const settings = {
                passwordPolicyMinLength: 8,
                passwordPolicyCharacters: [
                    "REQUIRES_LOWERCASE",
                    "REQUIRES_NUMBERS",
                    "REQUIRES_SYMBOLS",
                    "REQUIRES_UPPERCASE"
                ]
            };
            const {passwordPolicyMinLength, passwordPolicyCharacters} = settings;

            // Test minimum length
            if (password.length < passwordPolicyMinLength) {
                return Promise.reject("Password is too short")
            }

            // Test character requirements
            const requiredCharacterMap = {
                REQUIRES_LOWERCASE: /[a-z]/,
                REQUIRES_NUMBERS: /[0-9]/,
                REQUIRES_SYMBOLS: /[!@#$%^&*()\-_=+[\]{}|;:'",.<>/?]/,
                REQUIRES_UPPERCASE: /[A-Z]/
            };

            for (const characterRule of passwordPolicyCharacters) {
                if (!requiredCharacterMap[characterRule].test(password)) {
                    return Promise.reject(`Password must include ${characterRule.split('_').join(' ').toLowerCase()}`)
                }
            }
            return Promise.resolve()
        },
        generalEmail: (_, email) => {
            if (email === state.badEmail) {
                return Promise.reject("User not found - did you use Google?")
            }
            return Promise.resolve()
        },
        generalPassword: (_, password) => {
            if (password === state.badPassword) {
                return Promise.reject("Invalid credentials")
            }
            return Promise.resolve()
        },
        invalidConfirmCode: (_, code) => {
            if (code.toString().trim() === invalidCode.toString().trim()) {
                console.log('invalid code')
                return Promise.reject("Invalid Code")
            }
            return Promise.resolve()
        },
    }
    const forgotPassword = () => {
        setState(s => ({...s, screen: 'forgotPassword'}))
    }
    const cancelForgotPassword = () => {
        setState(s => ({...s, screen: 'signIn'}))
    }
    const sendForgotPasswordEmail = async () => {
        setState(s => ({...s, busy: true}))
        const valid = await forgotForm.validateFields()
        try {
            //console.log(valid)
            const result = await Auth.forgotPassword(valid.email)
            //console.log(result)
            setState(s => ({...s, busy: false, screen: 'newPassword', tempEmail: valid.email}))
        } catch (e) {
            //console.log(e.code)
            if (e.code === 'UserNotFoundException') {
                setState(s => ({...s, busy: false, badEmail: valid.email}))
            }
            setState(s => ({...s, busy: false}))
        }

    }
    const signInUser = async () => {
        const valid = await signInForm.validateFields()
        setState(s => ({...s, busy: true}))

        const init = {
            username: valid.email,
            password: valid.password
        }
        credRef.current = init
        try {
            await signIn(init)
            setState(s => ({...s, busy: false}))
        } catch (e) {
            if (e.code === 'UserNotConfirmedException') {
                resendConfirmationCode({username: valid.email})
                setState(s => ({...s, busy: false, screen: 'completeConfirmation'}))
            } else if (e.code === 'NotAuthorizedException') {
                setState(s => ({...s, busy: false, badPassword: valid.password}))
            } else if (e.code === 'UserNotFoundException') {
                setState(s => ({...s, busy: false, badEmail: valid.email}))
            } else {
                setState(s => ({...s, signInError: e.message,busy: false, screen: 'signIn'}) )
            }
        }
    }
    const forgotPasswordSubmit = async (username, code, newPassword) =>{
        try{
            const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
            setState(s => ({...s, busy: true}))
            credRef.current = {username, password: newPassword}
            tryAutoSignIn()
        }
        catch (e) {
            setState(s => ({...s, signInError: e.message,busy: false, screen: 'signIn'}) )
        }

    }

    const handleSignInErrorClose = () => {
        setState(s => ({...s, registrationError: null}))
        window.location.reload()
    }
    const signInWithGoogle = async () => {
        let customState = JSON.stringify({
            registration: false,
        })
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google, customState})
    }

    function SignInForm() {
        const formProps = {
            form: signInForm,
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };
        return <Form {...formProps} onFinish={signInUser} noValidate>
            <Form.Item

                shouldUpdate
                validateFirst
                label="Email Address"
                name="email"
                className="centered-form-item"
                rules={[
                    {required: true, message: `An email is required`},
                    {type: 'email', message: 'Please enter a valid email address'},
                    {validator: validators.generalEmail}
                ]}
            >

                <Input type="email" size="large" prefix={<MailOutlined style={{marginRight: 6}}/>}/>
            </Form.Item>

            <Form.Item
                shouldUpdate
                validateFirst
                label="Password"
                name="password"
                className="centered-form-item"
                rules={[
                    {required: true, message: `A password is required`},
                    {validator: validators.password},
                    {validator: validators.generalPassword}
                ]}
            >
                <Input.Password
                    iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                    size="large"
                    prefix={<LockOutlined style={{marginRight: 6}}/>}/>
            </Form.Item>


            <div style={{display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
                <Button type="dashed" size="small" style={{marginRight: 'auto'}} onClick={forgotPassword}>
                    Forgot my password
                </Button>

                <Button size="large" type={state.busy ? "disabled" : "primary"} htmlType="submit" disabled={state.busy}>
                    {state.busy && <>Signing In <LoadingOutlined/></>}
                    {!state.busy && <> Sign In</>}
                </Button>
            </div>

        </Form>
    }
    function ForgotForm() {
        const formProps = {
            form: forgotForm,
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };
        return <Form {...formProps} onFinish={sendForgotPasswordEmail}>
            <Form.Item
                shouldUpdate
                validateFirst
                label="Email Address"
                name="email"
                className="centered-form-item"
                rules={[
                    {required: true, message: `An email is required`},
                    {type: 'email', message: 'Please enter a valid email address'},
                    {validator: validators.generalEmail}
                ]}
            >

                <Input size="large" prefix={<MailOutlined style={{marginRight: 6}}/>}/>
            </Form.Item>
            <div style={{display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
                <Button type="dashed" size="small" style={{marginRight: 'auto'}} onClick={cancelForgotPassword}>
                    Cancel
                </Button>

                <Button size="large" type={state.busy ? "disabled" : "primary"} htmlType="submit">
                    {state.busy && <>Resetting Password <LoadingOutlined/></>}
                    {!state.busy && <> Reset Password</>}
                </Button>
            </div>

        </Form>
    }
    function ConfirmForm() {
        const [form] = Form.useForm()
        const confirmNewPassword = ()=>{
            form.validateFields().then(values=>{

                setState(s => ({...s, busy: true}))
                credRef.current = {username:state.tempEmail, password: values.password}
                forgotPasswordSubmit(state.tempEmail, values.code, values.password)
            })
        }

        return <Form
            form={form}
             onFinish={confirmNewPassword}
            //  standardised-form={confirmationForm}
            style={{display: 'flex', flexDirection: 'column'}}>
            <Form.Item
                label={"Confirmation Code"}
                name="code"
                rules={[ {required: true, message: `A code is required`},{validator: validators.invalidCode}]}
            >
                <Input disabled={state.busy} size="large" autoFocus/>
            </Form.Item>
            <Form.Item
                validateFirst
                label="Password"
                name="password"
                className="centered-form-item"
                rules={[
                    {required: true, message: `A password is required`},
                    {validator: validators.password}
                ]}
            >
                <Input.Password
                    disabled={state.busy}
                    iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                    size="large"
                    prefix={<LockOutlined style={{marginRight: 6}}/>}/>
            </Form.Item>

            <Form.Item
                validateFirst
                label="Password Again"
                name="password-repeat"
                className="centered-form-item"
                rules={[
                    {required: true, message: `A password is required`},
                    {validator: validators.passwordsMatch}
                ]}
            >

                <Input.Password
                    disabled={state.busy}
                    size="large"
                    prefix={<LockOutlined style={{marginRight: 6}}/>}
                    iconRender={(visible) => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
                />
            </Form.Item>
            <Button style={{marginTop: 24}} size="large" type={state.busy ? "disabled" : "primary"} htmlType="submit">
                {state.busy && <>Confirming <LoadingOutlined/></>}
                {!state.busy && <> Confirm</>}
            </Button>
        </Form>
    }
    function CompleteRegistrationForm() {
        const resendCode = async () => {
            await resendConfirmationCode({username: state.tempEmail})
        }

        return <>
            <Typography.Title style={{textAlign: 'center'}} level={5}>We've just sent a
                confirmation code to you email
            </Typography.Title>
            <Typography.Title level={4}>Enter the code below </Typography.Title>
            <ConfirmationForm/>
            <Button style={{marginTop: 24}} size="small" type="link" onClick={resendCode}>Re-send
                confirmation email</Button>
        </>
    }
    const testUserAndRedirect = async () => {
        const session = await Auth.currentSession();
        const payload = session && session.getIdToken().payload
        if (payload?.applicantId) {
            return navigate('/')
        }
        setState(s => ({...s, busy: false, screen: 'duplicate'}))
    }
    function ConfirmationForm() {
        const confirmUser = async () => {
            setState(s => ({...s, busy: true}))
            const code = confirmationForm.getFieldValue('code').toString().trim()
            try {
                const result = await confirmSignUp({username: credRef.current.username, code})
                if (result === "SUCCESS") {
                    setState(s => ({...s, confirmingText: 'Creating application'}))
                }

            } catch (e) {
                if (e.code === 'CodeMismatchException') {
                    setInvalidCode(code)
                }
                setState(s => ({...s, busy: false}))
            }
        }
        const confirmNumberDisabledProps = state.busy ? {disabled:true}:{}
        return <Form
            onFinish={confirmUser}
            form={confirmationForm}
            style={{display: 'flex', flexDirection: 'column'}}>
            <Form.Item
                name="code"
                rules={[{validator: validators.invalidConfirmCode}]}
            >
                <Input {...confirmNumberDisabledProps} size="large" autoFocus inputMode="numeric" pattern="[0-9]*"/>
            </Form.Item>

            <Button style={{marginTop: 24}} size="large" type={state.busy ? "disabled" : "primary"} htmlType="submit">
                {state.busy && <>{state.confirmingText} <Spin size="small" className="ms-6"/></>}
                {!state.busy && <> Confirm</>}
            </Button>
        </Form>
    }

    const tryAutoSignIn = async ()=>{
        const result = signIn(credRef.current)
    }
    useEffect(() => {
        confirmationForm.validateFields()
    }, [invalidCode]);
    useEffect(() => {
        const listener = Hub.listen('auth', (data) => {
            const event = data.payload.event;
            const user = data.payload.data;
            if (event === "signOut") {
                console.log('user signed out...');
            }
            if (event === "signIn") {
                testUserAndRedirect(user)
                //navigate('/')
            }
            if (event === "confirmSignUp") {
                console.log('confirm SignUp...');
                //signIn manually
                tryAutoSignIn()

            }
            if (event === 'autoSignIn') {
                testUserAndRedirect(user)
            } else if (event === 'autoSignIn_failure') {
                console.log('autoSignIn_failure...');
                // navigate('/login')
            }
        });
        return () => {
            listener()
        }
    }, [])

    useEffect(() => {
        if (state.badEmail || state.badPassword) {
            signInForm.validateFields().then(values => {
                //console.log(values)
            }).catch(e => {

            })
            forgotForm.validateFields().then(values => {
                //console.log(values)
            }).catch(e => {

            })
        }
    }, [state.badEmail, state.badPassword])


    return (
        <>
            <Card bordered={false} style={{position: 'relative'}}>

                <div style={{position: 'relative'}}>
                    {state.screen === 'signIn' && (
                        <>
                            <Button block size="large" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 8,
                                marginBottom: 24
                            }} onClick={signInWithGoogle}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.363" height="20.779"
                                     viewBox="0 0 20.363 20.779">
                                    <g id="google" transform="translate(1184.577 765.171)">
                                        <path id="Path_2" data-name="Path 2"
                                              d="M-1089.33-687.239v4.024h5.591a4.791,4.791,0,0,1-2.087,3.126l3.372,2.616a10.151,10.151,0,0,0,3.1-7.641,11.923,11.923,0,0,0-.189-2.125Z"
                                              transform="translate(-84.858 -69.432)" fill="#4285f4"/>
                                        <path id="Path_3" data-name="Path 3"
                                              d="M-1170.909-651.791l-.76.582-2.692,2.1h0a10.388,10.388,0,0,0,9.275,5.733,9.916,9.916,0,0,0,6.876-2.512l-3.372-2.616a6.189,6.189,0,0,1-3.5,1,6.2,6.2,0,0,1-5.818-4.279Z"
                                              transform="translate(-9.102 -101.013)" fill="#34a853"/>
                                        <path id="Path_4" data-name="Path 4"
                                              d="M-1183.463-712.61a10.262,10.262,0,0,0-1.114,4.656,10.263,10.263,0,0,0,1.114,4.656c0,.009,3.457-2.682,3.457-2.682a6.227,6.227,0,0,1-.331-1.974,6.226,6.226,0,0,1,.331-1.974Z"
                                              transform="translate(0 -46.828)" fill="#fbbc05"/>
                                        <path id="Path_5" data-name="Path 5"
                                              d="M-1165.087-761.034a5.669,5.669,0,0,1,3.976,1.549l2.975-2.975a9.967,9.967,0,0,0-6.951-2.711,10.371,10.371,0,0,0-9.275,5.733l3.457,2.682A6.2,6.2,0,0,1-1165.087-761.034Z"
                                              transform="translate(-9.101)" fill="#ea4335"/>
                                    </g>
                                </svg>

                                Sign in using Google
                            </Button>
                            <div style={{
                                borderTop: '1px solid #ccc',
                                position: 'relative',
                                marginBottom: 24,
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                        <span style={{
                            padding: '0 12px',
                            backgroundColor: 'white',
                            position: 'absolute',
                            top: -12
                        }}> or </span>
                            </div>
                            <SignInForm/>
                        </>
                    )}
                    {state.screen === 'forgotPassword' && (
                        <>
                            <Typography.Title style={{marginBottom: 24}} level={5}>Enter your email address to start the
                                password reset process</Typography.Title>
                            <ForgotForm/>
                        </>
                    )}
                    {state.screen === 'newPassword' && (
                        <>
                            <div className="mb-15">
                            <Typography.Title level={5}>We've just sent a
                                confirmation code
                                to {state.tempEmail} </Typography.Title>
                            <Typography.Text level={5}>Enter the code we sent you and new password  to regain access to your account</Typography.Text>
                            </div>
                                <ConfirmForm/>

                        </>
                    )}
                    {state.screen === 'completeConfirmation' && (
                        <>
                            <CompleteRegistrationForm/>
                        </>
                    )}

                </div>

            </Card>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 24
            }}>
                Don't have an account?
                <Link to={'/sign-up'}> <Button> Sign Up instead</Button> </Link>
            </div>
            <Modal footer={null} title="Sign In Error" open={!!state.signInError} closable={false}>
                <p><small>{state.signInError}</small></p>
                <p>Please reload the page and try again</p>
                <Button onClick={handleSignInErrorClose}>Reload</Button>
            </Modal>
        </>
    )
}

export default SignInPage;