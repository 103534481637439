import React, {createContext, useEffect, useRef, useState} from 'react';
import {App, Form} from "antd";
import FormSubmit from "./FormSubmit";
function SnazzyForm({children, popSubmit, submitAsFooter=true, ...props}) {
    const app = App.useApp()
    const [form] = Form.useForm()
    const [state, setState] = useState({
        initialValues: false,
        liveValues: false,
        busySaving: false
    })

    const onValuesChange = (map) => {
        setState(s => ({
            ...s,
            liveValues: {...s.liveValues,...map},
        }))
        if (props?.onValuesChange){
            props.onValuesChange(form, map)
        }
    }
    const validateAndHandle = async () => {
        let valid = false
        try {
            valid = await form.validateFields()

            setState(s=>({...s, busySaving: true}))
            if (!!valid){
                try{
                    await props.handleValid(valid)
                    //setState(s=>({...s, busySaving: false}))
                }
                catch (e) {
                    setState(s=>({...s, busySaving: false}))
                    console.log(e)
                    app.message.error('Save failed. Something went wrong there')
                }

            }
        } catch (errorInfo) {
            const firstErrorField = errorInfo.errorFields[0].name

            if (firstErrorField) {
                // Scroll to the error field
                let currentElement = document.querySelector(`[id="${firstErrorField}"]`);
                while (currentElement && !currentElement.classList.contains('ant-standardised-form-item')) {
                    currentElement = currentElement.parentElement;
                }
                if (currentElement) {
                    currentElement.scrollIntoView({ behavior: 'smooth'})
                }
            }
        }

    }
    const isSaveDisabled = () => {
        if (state.busySaving === true) return true
        if (props?.submitEnabled === true) return false
        const changesDetected = JSON.stringify(state.liveValues) !== JSON.stringify(state.initialValues)
        return  !changesDetected
    }
    // to make state available in unmount method, link state to a ref (alert not saved)
    const stateRef = useRef(state);
    stateRef.current = state;

    useEffect(() => {
        const initial = props.initialValues
        setState(s => ({
            ...s,
            initialValues: initial,
            liveValues: initial
        }))
        //form.setFieldsValue(initial)
        return ()=>{
            const changesDetected = JSON.stringify(stateRef.current.liveValues) !== JSON.stringify(stateRef.current.initialValues)
            if (changesDetected && !stateRef.current.busySaving){
                app.message.warning('Changes not saved!')
            }
        }
    }, [])
    return (

            <Form
                initialValues={props.initialValues}
                noValidate={true}
                className="mortgage-form"
                onValuesChange={onValuesChange}
                form={form}
                size="large"
                layout="horizontal"
                requiredMark={false}
                colon={false}
                validateTrigger="onBlur"
            >
                {children}

                <Form.Item noStyle>
                    <FormSubmit pop={popSubmit} onClick={validateAndHandle} disabled={isSaveDisabled()} busy={state.busySaving} submitAsFooter={submitAsFooter}/>
                </Form.Item>
            </Form>

    );
}

export default SnazzyForm;