import React, {useContext, useEffect, useState} from 'react';
import {Mortgage} from "./MortgageProvider";
import useMortgage from "../hooks/useMortgage";
export const MortgageInvitation = React.createContext({});
function MortgageInvitationProvider(props) {
    const [state, setState] = useState({
        open: false,
        invitationSent: null
    })
    const {mortgage} = useMortgage()
    let invitationSent = mortgage && mortgage.invitations.find(i=>{
        return !i.invalidated && !!i.sent
    })

    useEffect(()=>{
        if (!!mortgage){

            setState(s=>({
                ...s,
                invitationSent: !!invitationSent}))
        }
    }, [invitationSent])

    return (
        <MortgageInvitation.Provider
            value={{
                invitationSent: state.invitationSent,
                isOpen: state.open,
                onClose: () => setState(s => ({...s, open: false})),
                onOpen: (e) => {
                    console.log(e)
                    e.preventDefault()
                    e.stopPropagation()
                    setState(s => ({...s, open: true}))
                },
                setInvitationSent: () => setState(s=>({...s, invitationSent: true, open: false}))
            }}>
            {props.children}
        </MortgageInvitation.Provider>
    );
}

export default MortgageInvitationProvider;