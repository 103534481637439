
import AppScreen from "../../../app/App";
import {ScreenImage} from "../../../app/components/screen";
import bg1 from "../assets/images/bg1.svg";
import bg1dark from "../assets/images/bg1dark.svg";
import Welcome from "../../../app/components/screen/welcome";
import PageWidthLimiter from "../assets/elements/page-width-limiter";
import ButtonsRow from "./overlay-parts/landing/buttons-row";

function Mortgage({landing, buttons=false}) {
    return (
        <>
            <AppScreen title="Mortgage Application">

                <ScreenImage src={bg1} darkSrc={bg1dark}>

                    <Welcome {...landing.welcome}/>

                </ScreenImage>
                <PageWidthLimiter>
                    <div className="flex flex-col gap-4 ">
                        {landing.cards}
                        {!!buttons ? buttons :  <ButtonsRow key="buttons-row"/>}
                    </div>

                </PageWidthLimiter>

            </AppScreen>

        </>
    )
}

export default Mortgage;