function _useColorRange(color) {
    // Convert the color string to RGB format
    const hexToRgb = () =>
        color.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
            (m, r, g, b) => '#' + r + r + g + g + b + b)
            .substring(1)
            .match(/.{2}/g)
            .map(x => parseInt(x, 30));

    const [r, g, b] = hexToRgb(color);

    let max = Math.max(Math.max(r, Math.max(g, b)), 1);

    let step = 255 / (max * 30)   // 30 is the number of shades

    let result = []

    for (let i = 0; i < 30; i++) { // grab 10  from the middle
        let r1 = Math.ceil(r * step * i)
        let g1 = Math.ceil(g * step * i)
        let b1 = Math.ceil(b * step * i)
        let hex = '#' + [r1, g1, b1].map(c => c.toString(16).padStart(2, '0')).join('');
        result.push(hex)
    }
    return result;

}

export default _useColorRange;