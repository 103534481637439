import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {DarkModeContext} from "../../../../app/components/screen/DarkModeProvider";
import _useColorRange from "./_useColorRange";
import useScrollY from "../../../../app/components/screen/hooks/useScrollY";

BackgroundSvg.propTypes = {};

function BackgroundSvg({colorRange}) {

    const [darkMode] = useContext(DarkModeContext)
    const color = colorRange[15];
    const shades = colorRange;
    let radialGradient = {
        start: darkMode ? '#1b1c1c': shades[11],
        end: darkMode ? shades[5]: shades[16],
    }
    let linearGradient = {
        start: darkMode ? shades[5]: shades[10],
        end: darkMode ? shades[8] : shades[20],
    }

    return (
        <>

            <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" preserveAspectRatio="none" viewBox="0 0 800 400"
                 style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0}}>
                <defs>
                    <clipPath id={`clip-path-${color}`}>
                        <rect id={`Rectangle_4-${color}`} data-name="Rectangle 4" width="100%" height="100%"/>
                    </clipPath>
                    <radialGradient id={`radial-gradient-${color}`} cx="0.501" cy="0.998" r="2.57"
                                    gradientTransform="translate(0.177 0.706) scale(0.5 0.5)"
                                    gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor={radialGradient.start} stopOpacity="1"/>
                        <stop offset="1" stopColor={radialGradient.end}/>
                    </radialGradient>
                    <linearGradient id={`linear-gradient-${color}`} x1="0.082" y1="0.422" x2="0.557" y2="0.905"
                                    gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor={linearGradient.start} stopOpacity="0.2"/>
                        <stop offset="1" stopColor={linearGradient.end} stopOpacity="1"/>
                    </linearGradient>
                    <linearGradient id={`linear-gradient-2-${color}`} x1="0.962" y1="0.443" x2="0.456" y2="0.907"
                                    xlinkHref={`#linear-gradient-${color}`}/>
                    <linearGradient id={`linear-gradient-3-${color}`} x1="0.51" y1="0.437" x2="0.51" y2="0.909"
                                    xlinkHref={`#linear-gradient-${color}`}/>
                </defs>
                <g id={`e-${color}`} clipPath={`url(#clip-path-${color})`}>
                    <rect id={`Rectangle_3-${color}`} data-name="Rectangle 3" width="100%" height="100%" transform="translate(-1)"
                          fill={`url(#radial-gradient-${color})`}/>
                    <circle id={`Ellipse_1-${color}`} data-name="Ellipse 1" cx="300" cy="300" r="300"
                            transform="translate(-152 -294.65)" opacity="0.28" fill={`url(#linear-gradient-${color})`}/>
                    <circle id={`Ellipse_2-${color}`} data-name="Ellipse 2" cx="200" cy="200" r="200"
                            transform="translate(452 -59.65)" opacity="0.45" fill={`url(#linear-gradient-2-${color})`}/>
                    <circle id={`Ellipse_3-${color}`} data-name="Ellipse 3" cx="300" cy="300" r="300"
                            transform="translate(65 -211.82)" opacity="0.42" fill={`url(#linear-gradient-3-${color})`}/>
                </g>
            </svg>
            {/*<div className="absolute flex zi-6">*/}
            {/*    {*/}
            {/*        shades.map((shade, index) => {*/}
            {/*            return <div key={index} className="w-6 h-6" style={{backgroundColor: shade}}/>*/}
            {/*        }   )*/}
            {/*    }*/}
            {/*</div>*/}
        </>
    )
}

export default BackgroundSvg;