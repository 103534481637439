import {performGQL} from "../../assets/functions/functions";
export const userMortgageUploadsByMortgageID = /* GraphQL */ `
    query UserMortgageUploadsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageUploadsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageUploadsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                contentType
                fileName
                fileSize
                fileExtension
                stage
                section
                index
                status
                mortgageID
                reason
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
        }
    }
`;
export const userMortgagePropertyOnMortgagesByMortgageID = /* GraphQL */ `
    query UserMortgagePropertyOnMortgagesByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgagePropertyOnMortgageFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgagePropertyOnMortgagesByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                addressKnown
                address
                propertyType
                buildType
                newlyBuilt
                bedrooms
                bathrooms
                berScore
                propertyValue
                origPurchasePrice
                yearOfPurchase
                residingHere
                adultsBoarding
                adultsBoardingCount
                isRented
                monthlyRent
                isMortgaged
                remainingTerm
                lender
                accountNumber
                monthlyPayment
                currentRate
                outstanding
                mortgageID
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
        }
    }
`;
export const userMortgageProgressesByMortgageID = /* GraphQL */ `
    query UserMortgageProgressesByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageProgressFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageProgressesByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                applicationStage
                sectionType
                sectionName
                sectionIndex
                percent
                mortgageID
                percentUpdatedAt
                alertBody
                alertAuthor
                alertDismissed
                alertDone
                alertPending
                submittedOn
                submittedBy
                sectionChecked
                sectionCheckedBy
                expiresDate
                note
                arrangement
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
        }
    }
`;
export const userMortgageDynamicSectionsByMortgageID = /* GraphQL */ `
    query UserMortgageDynamicSectionsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageDynamicSectionFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageDynamicSectionsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                sectionStage
                sectionType
                sectionName
                sectionIndex
                sectionTitle
                sectionInstructions
                mortgageID
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
        }
    }
`;
export const userApplicantEmploymentIncomesByApplicantID = /* GraphQL */ `
    query UserApplicantEmploymentIncomesByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantEmploymentIncomeFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantEmploymentIncomesByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                applicantID
                employmentType
                employmentBasis
                positionHeld
                currentEmployerName
                currentEmployerAddress
                currentEmployerPhone
                currentEmployerContact
                currentEmployerStartDate
                previousEmployerStartDate
                previousEmployerEndDate
                nameOfBusiness
                natureOfBusiness
                businessAddress
                accountantName
                accountantAddress
                accountantPhone
                businessEstablished
                limitedCompany
                isDirector
                percentageOfShares
                selfIncomeSource
                grossBasic
                incomes
                publicSector
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
           
        }
    }
`;
export const userApplicantAssetsByApplicantID = /* GraphQL */ `
    query UserApplicantAssetsByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantAssetFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantAssetsByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                assetType
                institution
                description
                balance
                increasesFrequency
                increases
                applicantID
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
        }
    }
`;
export const userApplicantDebtsByApplicantID = /* GraphQL */ `
    query UserApplicantDebtsByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantDebtFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantDebtsByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                debtType
                lender
                outstanding
                monthlyPayment
                creditLimit
                inArrears
                arrears
                endDate
                clearedBeforeMortgage
                applicantID
                accountNumber
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
        }
    }
`;
export const userApplicantPropertiesByApplicantID = /* GraphQL */ `
    query UserApplicantPropertiesByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantPropertyFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantPropertiesByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                propertyType
                address
                estimatedValue
                isRented
                rentPerAnnum
                isMortgaged
                outstanding
                monthlyRepayments
                remainingTerm
                lender
                inArrears
                arrears
                applicantID
                bedrooms
                bathrooms
                isRestructured
                observeMID
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
        }
    }
`;
export const userMortgageInvitationsByMortgageID = /* GraphQL */ `
    query UserMortgageInvitationsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageInvitationFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageInvitationsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                mortgageID
                applicant2ID
                email
                inviter
                sent
                accepted
                invalidated
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
        }
    }
`;
export const userMortgageDocumentsByMortgageID = /* GraphQL */ `
    query UserMortgageDocumentsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageDocumentsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageDocumentsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                title
                description
                folder
                fileName
                fileExtension
                isDynamic
                mortgageID
                createdAt
                updatedAt
                _version
                _deleted
            }
            nextToken
        }
    }
`;
export const userApplicantActivitiesByApplicantID = /* GraphQL */ `
    query UserApplicantActivitiesByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantActivityFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantActivitiesByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                actions
                actionedOn
                actionedBy
                applicantID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
export const userMortgageQueriesByMortgageID = /* GraphQL */ `
    query UserMortgageQueriesByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageQueryFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageQueriesByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                mortgageID
                referenceType
                referenceId
                section
                index
                title
                date
                description
                amount
                query
                userAnswer
                userAnswerOn
                userAnswerBy
                adminAnswered
                userAnswered
                private
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
export const userApplicantCurrentAccountsByApplicantID = /* GraphQL */ `
    query UserApplicantCurrentAccountsByApplicantID(
        $applicantID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserApplicantCurrentAccountFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userApplicantCurrentAccountsByApplicantID(
            applicantID: $applicantID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                institution
                applicantID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
export const userMortgageAppointmentsByMortgageID = /* GraphQL */ `
    query UserMortgageAppointmentsByMortgageID(
        $mortgageID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelUserMortgageAppointmentFilterInput
        $limit: Int
        $nextToken: String
    ) {
        userMortgageAppointmentsByMortgageID(
            mortgageID: $mortgageID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                backendUserID
                appointmentType
                appointmentDate
                mortgageID
                readAccessGroup
                editAccessGroup
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                owner
                __typename
            }
            nextToken
            startedAt
            __typename
        }
    }
`;
export const getOperation = (path) => {
    switch (path) {
        case 'appointments':
            return userMortgageAppointmentsByMortgageID
        case 'uploads':
            return userMortgageUploadsByMortgageID
        case 'properties':
            return userMortgagePropertyOnMortgagesByMortgageID
        case 'mortgageProgress':
        case 'progress':
            return userMortgageProgressesByMortgageID
        case 'dynamicSections':
            return userMortgageDynamicSectionsByMortgageID
        case 'applicant1.employmentIncome':
            case 'applicant2.employmentIncome':
            return userApplicantEmploymentIncomesByApplicantID
        case 'applicant1.assets':
        case'applicant2.assets':
            return userApplicantAssetsByApplicantID
        case 'applicant1.debts':
        case 'applicant2.debts':
            return userApplicantDebtsByApplicantID
        case 'applicant1.properties':
        case 'applicant2.properties':
            return userApplicantPropertiesByApplicantID
        case 'applicant1.activities':
        case 'applicant2.activities':
            return userApplicantActivitiesByApplicantID
        case 'invitations':
            return userMortgageInvitationsByMortgageID
        case'UserMortgageDocuments':
            return userMortgageDocumentsByMortgageID
    }
}
async function getMoreItems(vars, path, setItems) {

    const operation = getOperation(path)
    const {items, nextToken: newNextToken} = await performGQL(vars, operation);
    if (items.length) {
        setItems(path, items)
    }
    if (newNextToken) {
        await getMoreItems({
            ...vars,
            nextToken: newNextToken
        }, path, setItems)
    }
}
export default getMoreItems