import questionProps from "../questionProps";
import {capitaliseFirst, wordLimit} from "../../../../assets/functions/functions";
export function propertyType(props) {
    return {
        label: "Property Type",
        text: "What type of property is this?",
        name: "propertyType",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Detached",
                    value: "DETACHED"
                },
                {
                    text: "Semi-detached",
                    value: "SEMIDETACHED"
                },
                {
                    text: "Terrace",
                    value: "TERRACE"
                },
                {
                    text: "Apartment",
                    value: "APARTMENT"
                },
                {
                    text: "Flat",
                    value: "FLAT"
                }
            ]
        },
        model: "properties",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.properties[${props.index}].propertyType`
    }
}
export function address(props) {
    return {
        text: "What is the address of the property?",
        name: "address",
        label: "Address",
        answer: {
            type: "address",
        },
        model: "properties",
        rules: [
            {required: true, message: `An address is required`},
        ],
        target: `applicant${props.applicant}.properties[${props.index}].address`
    }
}
export function bathrooms(props) {
    return {
        label: "Bathrooms",
        text: "How many bathroom has the property?",
        name: "bathrooms",
        answer: {
            type: "int",
        },
        model: "properties",
        rules: [
            {required: true, message: `A number is required`},
            {type: 'number', min: 1, message: "The minimum value here is 1"},
            {type: 'number', max: 20, message: "The maximum value here is 20"}

        ],
        target: `applicant${props.applicant}.properties[${props.index}].bathrooms`
    }
}
export function bedrooms(props) {
    return {
        label: "Bedrooms",
        text: "How many bedrooms has the property?",
        name: "bedrooms",
        answer: {
            type: "int",
        },
        model: "properties",
        rules: [
            {required: true, message: `A number is required`},
            {type: 'number', min: 1, message: "The minimum value here is 1"},
            {type: 'number', max: 20, message: "The maximum value here is 20"}

        ],
        target: `applicant${props.applicant}.properties[${props.index}].bedrooms`
    }
}
export function estimatedValue(props) {
    return {
        label: "Estimated Value",
        text: "What is the estimated value of the property?",
        name: "estimatedValue",
        answer: {
            type: "euro",
        },
        model: "properties",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 50000, message: "The minimum value here is €50,000"},
            {type: 'number', max: 5000000, message: "The maximum value here is €5,000,000"}
        ],
        target: `applicant${props.applicant}.properties[${props.index}].estimatedValue`
    }
}
export function isRented(props) {
    return {
        text: "Is this property rented out?",
        label: "Rented",
        name: "isRented",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "properties",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.properties[${props.index}].isRented`
    }
}
export function rentPerAnnum(props) {
    return {
        label: "Rent per annum",
        text: "How much rent does the property yield per year?",
        name: "rentPerAnnum",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return form.getFieldValue('isRented') !== true
        },
        model: "properties",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 5000, message: "The minimum value here is €5,000"},
            {type: 'number', max: 500000, message: "The maximum value here is €500,000"}
        ],
        target: `applicant${props.applicant}.properties[${props.index}].rentPerAnnum`
    }
}
export function isMortgaged(props) {
    return {
        text: "Is there a mortgage on the property?",
        label: "Mortgaged",
        name: "isMortgaged",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "properties",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.properties[${props.index}].isMortgaged`

    }
}
export function outstanding(props) {
    return {
        label: "Mortgage Balance",
        text: "What is the balance of the mortgage on this property?",
        name: "outstanding",
        answer: {
            type: "euro",
            validation: {
                min: {value: 1, message: "This must be a value greater than €1"},
                max: {value: 10000000, message: "You probably don't need a mortgage"}
            }
        },
        hides: (form) => {
            return form.getFieldValue('isMortgaged') !== true
        },
        model: "properties",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 2000000, message: "The maximum value here is €2,000,000"}
        ],
        target: `applicant${props.applicant}.properties[${props.index}].outstanding`
    }
}
export function monthlyRepayments(props) {
    return {
        text: "What are the monthly repayments?",
        label: "Monthly Repayments",
        name: "monthlyRepayments",
        answer: {
            type: "euro",
            validation: {
                min: {value: 1, message: "This must be a value greater than €1"},
                max: {value: 10000000, message: "You probably don't need a mortgage"}
            }
        },
        hides: (form) => {
            return form.getFieldValue('isMortgaged') !== true
        },
        model: "properties",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 50000, message: "The maximum value here is €50,000"}
        ],
        target: `applicant${props.applicant}.properties[${props.index}].monthlyRepayments`
    }
}
export function remainingTerm(props) {
    return {
        text: "How many years until fully repaid?",
        label: "Remaining Term",
        name: "remainingTerm",
        answer: {
            type: "int",
            props: {
                maxLength: 2
            },
            validation: {
                min: {value: 1, message: "This must be a value greater than 1"},
                max: {value: 50, message: "50 is the maximum here"}
            }
        },
        hides: (form) => {
            return form.getFieldValue('isMortgaged') !== true
        },
        model: "properties",
        rules: [
            {required: true, message: `A number is required`},
            {type: 'number', min: 0, message: "The minimum value here is 0"},
            {type: 'number', max: 35, message: "The maximum value here is 35"}
        ],
        target: `applicant${props.applicant}.properties[${props.index}].remainingTerm`
    }
}
export function lender(props) {
    return {
        text: "Who provided this mortgage?",
        label: "Lender",
        name: "lender",
        answer: {
            type: "lender",
        },
        hides: (form) => {
            return form.getFieldValue('isMortgaged') !== true
        },
        model: "properties",
        rules: [
            {required: true, message: `An name is required`},
        ],
        target: `applicant${props.applicant}.properties[${props.index}].lender`
    }
}
export function inArrears(props) {
    return {
        text: "Is the mortgage currently in arrears?",
        label: "Arrears",
        name: "inArrears",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('isMortgaged') !== true
        },
        model: "properties",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.properties[${props.index}].inArrears`
    }
}
export function arrears(props) {
    return {
        text: "By how much are you in arrears?",
        label: "Arrears amount",
        name: "arrears",
        answer: {
            type: "euro",
            validation: {
                min: {value: 1, message: "This must be a value greater than €1"},
                max: {value: 10000000, message: "You probably don't need a mortgage"}
            }
        },
        hides: (form) => {
            if (form.getFieldValue('isMortgaged') !== true) return true
            if (form.getFieldValue('inArrears') !== true) return true
        },
        model: "properties",
        rules: [
            {required: true, message: `An amount is required`},
            {type: 'number', min: 1, message: "The minimum value here is €1"},
            {type: 'number', max: 1000000, message: "The maximum value here is €1,000,000"}
        ],
        target: `applicant${props.applicant}.properties[${props.index}].arrears`
    }
}
export function restructured(props) {
    return {
        text: "Has this loan ever been restructured?",
        label: "Restructured",
        name: "isRestructured",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('isMortgaged') !== true
        },
        model: "properties",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.properties[${props.index}].isRestructured`
    }
}
export function propertyTitle(property) {
    let title = 'New Property'
    if (property.address) {
        title = wordLimit(property.address)
    }
    return title
}
export function haveAny(props){
    return {
        text: capitaliseFirst(props.you.doYou) + " have any other properties not included on this application?",
        label: "Any Properties",
        name: "hasProperties",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'},
            ]
        },
        model: "financial",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.financial.hasProperties`
    }
}

function properties(props) {
    const betterProps = questionProps(props)
    if (props?.index === 'have-any'){
        return [haveAny(betterProps)]
    }
    const questions = [
        propertyType(betterProps),
        address(betterProps),
        estimatedValue(betterProps),
        bedrooms(betterProps),
        bathrooms(betterProps),
        isRented(betterProps),
        rentPerAnnum(betterProps),
        isMortgaged(betterProps),
        lender(betterProps),
        outstanding(betterProps),
        monthlyRepayments(betterProps),
        remainingTerm(betterProps),
        restructured(betterProps),
        //inArrears(betterProps),
       // arrears(betterProps),
    ]
    return questions
}

export default properties;