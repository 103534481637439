import {useMemo, useRef, useState} from 'react';
import {Col,Popconfirm, Row, Tag, Tooltip, Typography} from "antd";
import {UploadStatuses} from "../../../../models";
import {
    CommentOutlined,
    DeleteOutlined,
   FileDoneOutlined, FileExclamationOutlined,
    FileImageOutlined,
    FilePdfOutlined, FileUnknownOutlined,
    LoadingOutlined,

} from "@ant-design/icons";
import {gray} from "@ant-design/colors";
import {fileNameAndExtension} from "../../../products/mortgage/assets/functions/functions";

function StandardUploadItem(props) {
    const [state, setState] = useState({
        editing: false
    })
    const inputRef = useRef(null)
    // const handleFilenameInputSave = (e) => {
    //     e.stopPropagation()
    //     props.handleRename(props.file.id, inputRef.current.input.value)
    //     setState(s => ({...s, editing: false}))
    // }
    // const handleEditClick = (e) => {
    //     e.stopPropagation()
    //     setState(s => ({...s, editing: !s.editing}))
    // }
    const handleOuterButtonClick = async (e) => {
        if (state.editing) return false
        props.handleOpen(props.file.id)
        //const url = await getS3Url(props.file.upload)
        //  window.open(url, 'document', "height=900,width=750,toolbar=0,location=0,menubar=0");
    }
    const handleDeleteClick = async (e) => {
        e.stopPropagation()
        props.handleDelete(props.file.id)
    }
    function RowIcon() {
        const style = {
            fontSize: 24,
            color: gray[3]
        }
        if (props.file.status?.toUpperCase() === UploadStatuses.UPLOADING || props.file?.originFileObj) {
            return <LoadingOutlined style={style}/>
        } else if (props.file.status === UploadStatuses.ACCEPTABLE) {
            return <FileDoneOutlined style={style}/>
        } else if (props.file.status === UploadStatuses.REJECTED) {
            return <FileExclamationOutlined style={style}/>
        } else if (props?.file?.contentType?.includes("image")) {
            return <FileImageOutlined style={style}/>
        } else if (props?.file?.contentType?.includes("pdf")) {
            return <FilePdfOutlined style={style}/>
        }
        return <FileUnknownOutlined style={style}/>
    }
    const icon = useMemo(() => {
        return <RowIcon/>
    }, [props.file.status])

    function RowTags() {
        const tags = []
        switch (props.file.status?.toUpperCase()) {
            case UploadStatuses.REVIEWING:
            case UploadStatuses.PENDING:
                tags.push(
                    {
                        title: "Pending Review",
                        color: "geekblue"
                    })
                break
            case UploadStatuses.ACCEPTABLE:
                tags.push(
                    {
                        title: "Acceptable",
                        color: "green"
                    })
                break
            case UploadStatuses.REJECTED:
                let tag = {
                    title: "Returned",
                    color: "red",
                }
                tags.push(tag)
                break
            default:
        }
        return tags.map(tag => {
            return <Tag color={tag.color} key={`${tag.title}/${props.file.id}`}>{tag.title}</Tag>
        })
    }
    let uploaded = [UploadStatuses.ACCEPTABLE, UploadStatuses.PENDING, UploadStatuses.REJECTED, UploadStatuses.REVIEWING].includes(props.file.status)
    const {fileName} = fileNameAndExtension(props.file.name)

    const borderClassName = props.index === 0 ? '' : 'border-t border-gray-200 dark:border-gray-800'

    return (
        <Row align="middle" className={`py-4 ${borderClassName}`} onClick={handleOuterButtonClick} gutter={15} wrap={false}>
            <Col>
                {icon}
            </Col>
            <Col flex={1}>
                <Typography.Text ellipsis>
                    {fileName}
                </Typography.Text>
            </Col>
            <Col flex={1}>
                {props.file.reason && (
                    <Typography.Text ellipsis className="text-xs m-0" style={{verticalAlign:'middle'}}>
                        <Tooltip title={props.file.reason}>
                            <CommentOutlined className="text-red-500 dark:text-red-500 me-2"/>
                        </Tooltip>
                        {props.file.reason}
                    </Typography.Text>
                )}

            </Col>
            <Col>
                <RowTags/>

            </Col>
            <Col>
                {uploaded && (
                    <Popconfirm
                        placement="topLeft"
                        description={'Click Yes to delete'}
                        onConfirm={handleDeleteClick}
                        okText="Yes"
                        cancelText="No"
                        title={"Are you sure you want to delete this upload"}

                    >

                        <DeleteOutlined className="text-gray-400 dark:text-gray-200"
                                        onClick={(e) => e.stopPropagation()}/>

                    </Popconfirm>
                )}
            </Col>
        </Row>
    );
}

export default StandardUploadItem;