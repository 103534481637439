import {capitaliseFirst} from "../../../../assets/functions/functions";
import questionProps from "../questionProps";
import {EmploymentTypes} from "../../../../../../../models";

export function incomeChecks(props) {
    return {
        label: "Additional Income",
        text: `In addition to ${props.you.your} basic income, which of the following types of income (if any) ` + (props.you.doYou) + " receive? ",
        subtext: "(if any)",
        name: "incomeChecks",
        answer: {
            type: "multichoice",
            optional: true,
            choices: [
                {
                    value: "OVERTIME",
                    text: "Overtime"
                }, {
                    value: "BONUS",
                    text: "Bonus"
                }, {
                    value: "COMMISSION",
                    text: "Commission"
                }, {
                    value: "MAINTENANCE",
                    text: "Maintenance"
                },
                // {
                //     value: "WELFARE",
                //     text: "Social Welfare"
                // },
                {
                    value: "SECONDJOB",
                    text: "2nd Job"
                }, {
                    value: "RENT",
                    text: "Rental Incomes"
                }, {
                    value: "DIVIDENDS",
                    text: "Dividends"
                }, {
                    value: "OTHER",
                    text: "Other"
                }
            ]
        },
        model: "income",
        rules: [],
        target: `applicant${props.applicant}.income.incomeChecks`
    }

}
export function hasIncome(props) {
    return {
        label: "External Income",
        text: capitaliseFirst(props.you.doYou) + " have any income from non-work related source",
        name: "hasIncome",
        answer: {
            type: "choice",
            choices: [
                {
                    text: "Yes",
                    value: true
                },
                {
                    text: "No",
                    value: false
                }
            ]
        },
        model: "income",
        rules: [],
        target: `applicant${props.applicant}.financial.hasIncome`
    }
}
export function incomeChecksAlt(props) {
    return {
        label: "External Sources",
        text: "Check which of these apply",
        name: "incomeChecks",
        answer: {
            type: "multichoice",
            optional: true,
            choices: [
                {
                    value: "MAINTENANCE",
                    text: "Maintenance"
                }, {
                    value: "RENT",
                    text: "Rental Incomes"
                }, {
                    value: "DIVIDENDS",
                    text: "Dividends"
                }, {
                    value: "OTHER",
                    text: "Other"
                }
            ]
        },
        hides: (form) => {
            return form.getFieldValue('hasIncome') !== true
        },
        model: "income",
        rules: [{required: true, message: `At least one income type is required`}],
        target: `applicant${props.applicant}.income.incomeChecks`
    }

}
export function grossBasic(props) {
    return {
        label: "Gross Basic Pay",
        text: "What is " + props.you.your + " gross basic yearly salary? (before tax)",
        name: "grossBasic",
        answer: {
            type: "euro",
        },
        hides: (form) => {
           return !form.getFieldValue('employmentType') || ![EmploymentTypes.SELFEMPLOYED, EmploymentTypes.EMPLOYED].includes(form.getFieldValue('employmentType'))
        },
        model: "income",
        rules: [
            {required: true, message: `An basic pay amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than €1'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: props?.index ? `applicant${props.applicant}.employmentIncome[${props.index}].grossBasic` : `applicant${props.applicant}.income.grossBasic`
    }
}
export function overtime(props) {
    return {
        label: "Overtime Amount",
        text: "How much over-time pay " + props.you.doYou + " earn in a year?",
        name: "overtime",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('OVERTIME')
        },
        model: "income",
        rules: [
            {required: true, message: `An overtime amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.overtime`
    }
}
export function bonus(props) {
    return {
        label: "Bonus Amount",
        text: "How much bonus " + props.you.doYou + " receive in a year?",
        name: "bonus",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('BONUS')
        },
        model: "income",
        rules: [
            {required: true, message: `A bonus amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.bonus`
    }
}
export function commission(props) {
    return {
        label: "Commission Amount",
        text: "How much commission " + props.you.doYou + " receive in a year?",
        name: "commission",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('COMMISSION')
        },
        model: "income",
        rules: [
            {required: true, message: `A commission amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.commission`
    }
}
export function maintenance(props) {
    return {
        label: "Maintenance Amount",
        text: "How much maintenance " + props.you.doYou + " receive in a year?",
        name: "maintenance",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('MAINTENANCE')
        },
        model: "income",
        rules: [
            {required: true, message: `A maintenance amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${props.applicant}.income.maintenance`
    }
}
export function welfare(props) {
    return {
        label: "Social Welfare Amount",
        text: "How much social welfare " + props.you.doYou + " receive in a year?",
        name: "welfare",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('WELFARE')
        },
        model: "income",
        rules: [
            {required: true, message: `A welfare amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 100000, message: 'Must be less than €100,000'}
        ],
        target: `applicant${props.applicant}.income.welfare`
    }
}
export function secondjob(props) {
    return {
        label: "Second Job",
        text: "How much " + props.you.doYou + " receive (before tax) from the second job in a year?",
        name: "secondjob",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('SECONDJOB')
        },
        model: "income",
        rules: [
            {required: true, message: `A second job amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.secondjob`
    }
}
export function rentalIncome(props) {
    return {
        label: "Rental Income",
        text: "How much " + props.you.doYou + " receive from all rentals in a year?",
        name: "rentalIncome",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('RENT')
        },
        model: "income",
        rules: [
            {required: true, message: `A rental amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.rentalIncome`
    }
}
export function dividends(props) {
    return {
        label: "Dividends Amount",
        text: "How much " + props.you.doYou + " receive from all dividends in a year?",
        name: "dividends",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('DIVIDENDS')
        },
        model: "income",
        rules: [
            {required: true, message: `A dividends amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.dividends`
    }
}
export function investments(props) {
    return {
        label: "Investments Amount",
        text: "How much " + props.you.doYou + " receive from all investments in a year?",
        name: "investments",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('INVESTMENTS')
        },
        model: "income",
        rules: [
            {required: true, message: `A investments amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.investments`
    }
}
export function other(props) {
    return {
        label: "Other Incomes Amount",
        text: "How much " + props.you.doYou + " receive in a year from this?",
        name: "other",
        answer: {
            type: "euro",
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('OTHER')
        },
        model: "income",
        rules: [
            {required: true, message: `The other amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than zero'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.income.other`
    }
}
export function otherSourceDetails(props) {
    return {
        label: "Other Incomes Details",
        text: "Supply details from where this income comes",
        name: "otherSourceDetails",
        answer: {
            type: "textarea"
        },
        hides: (form) => {
            return !form.getFieldValue('incomeChecks')?.includes('OTHER')
        },
        model: "income",
        rules: [
            {required: true, message: `A description is required`},
        ],
        target: `applicant${props.applicant}.income.otherSourceDetails`
    }
}
export function nonEuroCurrency(props) {
    return {
        label: "Non Euro Earned",
        text: capitaliseFirst(props.you.doYou) + " receive any income in a non-euro currency?",
        name: "nonEuroCurrency",
        answer: {
            type: "choice",
            choices: [{
                value: true,
                text: "Yes"
            }, {
                value: false,
                text: "No"
            }]
        },
        model: "income",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: props?.index ? `applicant${props.applicant}.employmentIncome[${props.index}].nonEuroCurrency`: `applicant${props.applicant}.income.nonEuroCurrency`
    }
}
function income(props) {
    const betterProps = questionProps(props)
    const questions = []
    const employment = props.mortgage[`applicant${props.applicant}`].employment
    if (employment.employmentType === "EMPLOYED" || employment.employmentType === "SELFEMPLOYED") {
        questions.push(
            grossBasic(betterProps),
            incomeChecks(betterProps),
            overtime(betterProps),
            bonus(betterProps),
            commission(betterProps),
            maintenance(betterProps),
            secondjob(betterProps),
            rentalIncome(betterProps),
            dividends(betterProps),
            other(betterProps),
            otherSourceDetails(betterProps),
            nonEuroCurrency(betterProps)
        )
    } else {
        questions.push(
            hasIncome(betterProps),
            incomeChecksAlt(betterProps)
        )
        const maintenanceObj = maintenance(betterProps)
        maintenanceObj.hides = (form) => {
            return form.getFieldValue('hasIncome') !== true || !form.getFieldValue('incomeChecks')?.includes('MAINTENANCE');
        }

        questions.push(maintenanceObj)

        const rentalIncomeObj = rentalIncome(betterProps)
        rentalIncomeObj.hides = (form) => {
            return form.getFieldValue('hasIncome') !== true || !form.getFieldValue('incomeChecks')?.includes('RENT');
        }

        const dividendsObj = dividends(betterProps)
        dividendsObj.hides = (form) => {
            return form.getFieldValue('hasIncome') !== true || !form.getFieldValue('incomeChecks')?.includes('DIVIDENDS');
        }

        const otherObj = other(betterProps)
        otherObj.hides = (form) => {
            return form.getFieldValue('hasIncome') !== true || !form.getFieldValue('incomeChecks')?.includes('OTHER');
        }
        const otherSourceDetailsObj = otherSourceDetails(betterProps)
        otherSourceDetailsObj.hides = (form) => {
            return form.getFieldValue('hasIncome') !== true || !form.getFieldValue('incomeChecks')?.includes('OTHER');
        }

        let nonEuro = nonEuroCurrency(betterProps)
        nonEuro.hides = (form) => {
            return form.getFieldValue('hasIncome') !== true
        }
        questions.push(
            maintenanceObj,
            rentalIncomeObj,
            dividendsObj,
            otherObj,
            otherSourceDetailsObj,
            nonEuro
        )
    }
    return questions
}

export default income;