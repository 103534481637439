import React, {useState} from 'react';
import {InboxOutlined, UploadOutlined} from "@ant-design/icons";
import {Button, List, Upload} from "antd";
import StandardUploadItem from "./StandardUploadItem";
import {sanitizeFileName} from "../../../products/mortgage/assets/functions/functions";

const {Dragger} = Upload;

function StandardUpload(props) {
    const [fileList, setFileList] = useState(props.fileList);
    // const handleUploadComplete = (event) => {
    //
    //     setFileList(s => s.map(file => {
    //         if (file.uid === event.uid) {
    //             return {
    //                 id: event.item.id,
    //                 uid: file.uid,
    //                 name: event.item.fileName,
    //                 contentType: event.item.contentType,
    //                 extension: event.item.fileExtension,
    //                 status: event.item.status,
    //                 created: event.item.createdAt,
    //                 reason: null,
    //             }
    //         }
    //         return file
    //     }))
    // }
    const handoverFileToS3 = async (file, onProgress) => {

        try {
            const settings = {
                file,
                progressCallback: (progress) => {

                    let percent = Math.floor((progress.loaded / progress.total) * 100)
                    onProgress({percent})
                    //setFileProgress(s => ({...s, [file.uid]: percent}))
                },
                completeCallback: (event) => {
                    //console.log(event)
                  //  handleUploadComplete({...event, uid: file.uid})
                    //console.log(`Successfully uploaded ${event.key}`);
                    //props.onComplete(props.model)
                },
                errorCallback: (err) => {
                    //setState({error: true})
                    console.error('Unexpected error while uploading', err);
                }
            }
            // pass to the upload handler
            await props.handleUpload(settings)

        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    };
    const customRequest = async ({file, onSuccess, onError, onProgress}) => {
        try {
            await handoverFileToS3(file, onProgress);
            onSuccess();
        } catch (error) {
            onError(error);
        }
    }
    const handleChange = ({file, fileList}) => {
        setFileList(fileList)
    };
    const handleRename = async (id, name) => {
        const sanitized = sanitizeFileName(name)
        const orig = fileList.find(item=>item.id === id)
        //console.log({orig,sanitized})
        if (sanitized !==  orig.name){
            setFileList(s => {
                return s.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            status: 'UPLOADING'
                        }
                    }
                    return item
                })
            })
            const newName = await props.rename(id, sanitized)
            setFileList(s => {
                return s.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            name: newName,
                            status: orig.status
                        }
                    }
                    return item
                })
            })
        }

    }
    const handleDelete = async (id) => {
        let origStatus
        setFileList(s => {

            return s.map(item => {
                if (item.id === id) {
                    origStatus = item.status
                    return {
                        ...item,
                        status: 'UPLOADING'
                    }
                }
                return item
            })

        })

        await props.delete(id)
        setFileList(s => {
            return s.filter(item => item.id !== id)
        })

    }
    const handleOpen = async (id) => {
        const url = await props.getUrl(id)
        window.open(url, 'uploaded', "height=900,width=750,toolbar=0,location=0,menubar=0");
    }

    const customItemRender = (originNode, file, currFileList) => {
       // console.log({file, currFileList, originNode})
        const index = currFileList.findIndex(item => item.uid === file.uid)
        return <StandardUploadItem
            index={index}
            key={file.uid + file.name}
            file={file}
            handleRename={handleRename}
            handleDelete={handleDelete}
            handleOpen={handleOpen}
        />
    };

    const draggerProps = {
        accept: ".pdf, .png,.jpg",
        name: 'file',
        multiple: true,
        fileList: props.fileList,
        itemRender: customItemRender,
        onChange: handleChange,
        customRequest: customRequest,
    };

    return (
        <div className="p-4">
            <Dragger {...draggerProps} style={{borderRadius:'0.5rem'}}>

                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="hidden sm:block text-neutral-600 dark:text-neutral-200">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint text-sm mb-2">
                    Supports pdfs and images
                </p>
                <Button style={{marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', gap: 3}} icon={<UploadOutlined/>}>
                    Click
                    <div className="hidden sm:inline ">&nbsp;/&nbsp;Drag</div>

                    to Upload
                </Button>
            </Dragger>
        </div>
    );
}

export default StandardUpload;