import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import {Form, Input} from "antd";
import {Auth} from "aws-amplify";
import {LoadingOutlined, LockOutlined} from "@ant-design/icons";

function AdminSignIn(props) {
    const location = useLocation()
    const [state,setState] = useState({
        busy: false,
        badPassword: false
    })
    const [form] = Form.useForm()
    const validators = {
        password: (_, password) => {
            const settings = {
                passwordPolicyMinLength: 8,
                passwordPolicyCharacters: [
                    "REQUIRES_LOWERCASE",
                    "REQUIRES_NUMBERS",
                    "REQUIRES_SYMBOLS",
                    "REQUIRES_UPPERCASE"
                ]
            };
            const {passwordPolicyMinLength, passwordPolicyCharacters} = settings;

            // Test minimum length
            if (password.length < passwordPolicyMinLength) {
                return Promise.reject("Password is too short")
            }

            // Test character requirements
            const requiredCharacterMap = {
                REQUIRES_LOWERCASE: /[a-z]/,
                REQUIRES_NUMBERS: /[0-9]/,
                REQUIRES_SYMBOLS: /[!@#$%^&*()\-_=+[\]{}|;:'",.<>/?]/,
                REQUIRES_UPPERCASE: /[A-Z]/
            };

            for (const characterRule of passwordPolicyCharacters) {
                if (!requiredCharacterMap[characterRule].test(password)) {
                    return Promise.reject(`Password must include ${characterRule.split('_').join(' ').toLowerCase()}`)
                }
            }
            return Promise.resolve()
        },
        badPassword: (_, password) => {
            if (password === state.badPassword) {
                return Promise.reject("Invalid credentials")
            }
            return Promise.resolve()
        }
    }
    const inputRef = useRef()
    const searchParams = new URLSearchParams(location.search);
    const address = searchParams.get('admin-viewer')
    const handleSignIn = async (map)=>{
        try {
            const valid1 = await form.validateFields()

            setState(s => ({...s, busy: true}))
            await Auth.signIn(address, map.password);
            props.handleSuccessfulSignin()
        } catch (e) {
            if (e.code === 'NotAuthorizedException') {
                setState(s => ({...s, busy: false, badPassword: map.password}))
            } else {
                setState(s => ({...s, busy: false}))
            }

        }
    }
    useEffect(() => {
        setTimeout(()=>{
            inputRef.current.focus()
        },50)
    }, []);
    useEffect(()=>{
        form.validateFields()
    },[state.badPassword])
    return (
        <div className="flex items-center justify-center" style={{height:'100vh'}}>

            <Form form={form} onFinish={handleSignIn}>
                <div className="d-row a-center">
                    <Form.Item
                        className="mb-0"
                        label="Password"
                        name="password"
                        validateFirst
                        type="password"
                        rules={[
                            {required: true, message: `A password is required`},
                            {validator: validators.password},
                            {validator: validators.badPassword}
                        ]}
                    >
                        <Input.Password  prefix={state.busy ? <LoadingOutlined/> : <LockOutlined />} ref={inputRef}/>

                    </Form.Item>
                </div>


            </Form>


        </div>
    );
}

export default AdminSignIn;