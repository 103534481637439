import React from 'react';
import Reveal from "./Reveal";
import {Avatar, Dropdown, Typography} from "antd";
import {geekblue} from "@ant-design/colors";
import {
    DesktopOutlined,
    UserDeleteOutlined,
    UserOutlined,
    MoonOutlined,
    HomeOutlined,
    SunOutlined
} from "@ant-design/icons";
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import useOverlay from "../overlay/useOverlay";
import SetupMortgage from "../../../../products/mortgage/components/overlays/setup/MortgageSetupOverlay";
import useMortgage from "../../../../products/mortgage/data-source/hooks/useMortgage";
import {ApplicationStages} from "../../../../../models";

function Nav({darkMode, toggleDarkMode, title, bgColor, ...sizes}) {
    const navigate = useNavigate()
    const {mortgage} = useMortgage()
    const signOut = async () => {
        await Auth.signOut()
        navigate('/sign-in')
    }
    const {launch} = useOverlay(SetupMortgage, "SetupMortgage")
    const dropdownMenuItems = {
        items: []
    }
    if (!!mortgage && mortgage.applicationStage === ApplicationStages.SETUP) {
        dropdownMenuItems.items.push({
            key: 'mortgage',
            label: 'Reconfigure Mortgage',
            icon: <HomeOutlined/>,
            onClick: launch
        })
    }
    dropdownMenuItems.items.push(
        {
            key: 'website',
            label: <a href="https://www.sussd.ie" rel="noreferrer"> Main Website</a>,
            icon: <DesktopOutlined/>,
        },
        {
            key: 'logout',
            label: 'Sign out',
            icon: <UserDeleteOutlined/>,
            onClick: signOut
        }
)

    return (
        <Reveal darkMode={darkMode} bgColor={bgColor}>
            <div className="max-w-screen-md mx-auto">
                <div className="flex flex-row gap-3 items-center py-3 px-4">
                    <Dropdown menu={dropdownMenuItems} trigger="click">
                        <Avatar
                            style={{backgroundColor: darkMode ? 'rgb(49, 61, 105)' : geekblue.primary}}><UserOutlined/></Avatar>
                    </Dropdown>
                    <Typography.Title level={5} className="mb-0!important mt-0"> <span
                        className="text-white"> {title} </span> </Typography.Title>
                    <div className="ms-auto">
                        <Avatar  onClick={toggleDarkMode} style={{cursor:'pointer',backgroundColor: 'transparent', border: '1px solid rgba(225,225,225,0.5)'}}>

                            {!!darkMode && <MoonOutlined />}
                            {!darkMode && <SunOutlined/>}

                        </Avatar>
                    </div>
                </div>
            </div>

            {/*<Row justify="center" >*/}
            {/*    <Col {...sizes}>*/}
            {/*        <div className="flex flex-row gap-3 items-center py-3">*/}
            {/*            <Dropdown menu={dropdownMenuItems} trigger="click" >*/}
            {/*                <Avatar style={{backgroundColor: darkMode ? 'rgb(49, 61, 105)' : geekblue.primary}}><UserOutlined/></Avatar>*/}
            {/*            </Dropdown>*/}
            {/*            <Typography.Title level={5} className="mb-0!important mt-0"> <span className="text-white"> {title} </span> </Typography.Title>*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </Reveal>
    )
}

export default Nav;