import React from 'react';
import {Card} from "antd";

function QuestionsCard({children, className, roundedBottom = true, header}) {
    let outerClass = roundedBottom ? '':'rounded-b-none'
    let fullClass = "border-gray-300 dark:border-neutral-700 " + outerClass
    return (
        <Card styles={{body:{padding: 0}}} bordered className={fullClass} title={header}>
            {!!className && (
                <div className={className}>
                    {children}
                </div>
            )}
            {!className && children}
        </Card>
    );
}

export default QuestionsCard;