import React, {useContext} from 'react';
import {Mortgage} from "../providers/MortgageProvider";

function useMortgage(props) {
    const mortgage = useContext(Mortgage)
    const generateFlowItemSetterFunction = ()=>{
        return (key, doMutate = true)=>{
            let existing = JSON.parse(mortgage.completedFlowItems || "{}")

            if (!existing.hasOwnProperty(key)) {
                const data = {...existing}
                data[key] = (new Date()).toISOString()
                let update = {
                    target: `completedFlowItems`,
                    value: JSON.stringify(data)
                }
                if (doMutate) {
                    return mortgage.mutate({update})
                }
                else{
                    return update
                }
            }
        }
    }
    const generateFlowItemGetterFunction = ()=>{
        return (key)=>{
            return JSON.parse(mortgage.completedFlowItems || "{}").hasOwnProperty(key)
        }
    }
    const expandMortgage = () => {
        return {
            completedFlowItems: JSON.parse(mortgage.completedFlowItems || "{}"),
            setComplete: generateFlowItemSetterFunction(),
            getComplete: generateFlowItemGetterFunction()
        }
    }

    if (!mortgage) return {mortgage: null}

    return {
        mortgage:{
            ...mortgage,
            // applicant1: {
            //     ...mortgage.applicant1,
            //     ...expandApp(1)
            // },
            // applicant2: {
            //     ...mortgage.applicant2,
            //     ...expandApp(2)
            // },
            ...expandMortgage(),
            invitationSent: mortgage.invitations.find(i=>{return !i.invalidated})
        }
    }

}

export default useMortgage;