import {useEffect, useState} from 'react';
import {API, Auth, graphqlOperation} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import {Spin} from "antd";
export const listMortgagesBasic = /* GraphQL */ `
    query ListUserMortgages(
        $filter: ModelUserMortgageFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listUserMortgages(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                twoApplicants
                mortgageType
                applicant1ID
                applicant2ID
                applicationStage
                lastSeen
                advisorID
                _version
                _deleted
            }
            nextToken
            startedAt
        }
    }
`;
function ProductDirector() {
    const [state, setState] = useState({
        fetching: false,
        products: []
    })
    const navigate = useNavigate()

    useEffect(() => {
        const fetchMortgages = async (applicantId) => {
            let mortgages = []
            const getMoreMortgages = async (vars) => {
                const response = await API.graphql(
                    graphqlOperation(listMortgagesBasic, vars)
                )
                const {items, nextToken: newNextToken} = response.data.listUserMortgages;
                if (items.length) {
                    mortgages = [...mortgages, ...items]
                }
                if (newNextToken) {
                    await getMoreMortgages({
                        ...vars,
                        nextToken: newNextToken
                    })
                }
            }
            await getMoreMortgages({
                filter: {
                    or: [
                        {applicant1ID: {eq: applicantId}},
                        {applicant2ID: {eq: applicantId}}
                    ]
                }
            })
            return mortgages
        }
        const loadProducts = async (applicantId) => {
            setState(s => ({...s, fetching: true}))
            let products = []
            const mortgages = await fetchMortgages(applicantId)
            products = [...products, {
                product: 'mortgage',
                products: mortgages
            }]
            setState(s => ({...s, products}))
            let selected = products[0]

            let path = `/${selected.product}/${selected.products[0].id}`
            navigate(path)
        }
        const redirect = async () => {
            if (!state.products.length) {
                try {
                    const session = await Auth.currentSession()
                    const payload = session && session.getIdToken().payload
                    if (payload?.applicantId) {
                        await loadProducts(payload?.applicantId)
                    }
                } catch (e) {
                    navigate('/sign-in')
                }
            }
        }
        redirect()
    }, [])

    return <div className="pt-52 w-full flex flex-col gap-2 items-center">
        <div>
            <Spin size="large"/>
        </div>
        One moment ...
    </div>;
}

export default ProductDirector;