import React, {useContext, useEffect, useState} from 'react';
import {Mortgage} from "../providers/MortgageProvider";

function useCurrentUserActivity() {
    const mortgage = useContext(Mortgage)

    const [state,setState] = useState({activity:false})
    const updateActivity = (activity) => {
        if (!!activity) setState(s=>({...s,activity}))

    }
    useEffect(() => {
        if (!!state.activity){
            setTimeout(async ()=>{
                if (mortgage){
                    const sortFunction = (a,b)=>new Date(b.createdAt) - new Date(a.createdAt)
                    let currentActivity = false
                    const update = {}
                    if (mortgage.applicant1.isCurrentUser){
                        currentActivity = mortgage.applicant1.activity.length > 0 ? [...mortgage.applicant1.activity].sort(sortFunction)[0] : false
                        if (currentActivity){
                            update.target = `applicant1.activity[${currentActivity.id}]`
                        }
                    }
                    else if (mortgage.applicant2.isCurrentUser){
                        currentActivity = mortgage.applicant2.activity.length > 0 ? [...mortgage.applicant2.activity].sort(sortFunction)[0] : false
                        if (currentActivity) {
                            update.target = `applicant2.activity[${currentActivity.id}]`
                        }
                    }
                    if (currentActivity){
                        const actions = !!currentActivity.actions ? JSON.parse(currentActivity.actions) : {}
                        if (!actions.hasOwnProperty('mortgage')){
                            actions.mortgage = {}
                        }
                        if (!actions.mortgage.hasOwnProperty(mortgage.id)){
                            actions.mortgage[mortgage.id] = {}
                        }
                        if (!actions.mortgage[mortgage.id].hasOwnProperty(state.activity)){
                            actions.mortgage[mortgage.id][state.activity] = new Date().toISOString()
                            // grab the user agent
                            update.pairs = {
                                actions: JSON.stringify(actions),
                            }
                            if (!currentActivity.userAgent){
                                update.pairs.userAgent = ` ${window.innerWidth} × ${window.innerHeight} | ${navigator.userAgent}`
                            }

                            await mortgage.mutate({update})
                        }
                    }
                    else{
                        console.log('no activity to update')
                    }
                }
                else{
                    console.log('no mortgage to update')
                }
            },200)
        }

    }, [state.activity]);
    useEffect(()=>{
        if (!!mortgage){
            if (mortgage.applicant1.isCurrentUser && mortgage.applicant1.activity.length === 1 && !mortgage.applicant1.activity[0].actions){
                updateActivity('started application')
            }
            else if (mortgage.applicant2.isCurrentUser && mortgage.applicant2.activity.length === 1 && !mortgage.applicant2.activity[0].actions){
                updateActivity('joined application')
            }
        }
    }, [mortgage])
    return updateActivity

}

export default useCurrentUserActivity;