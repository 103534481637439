import React, {useState} from 'react';
import {Switch, List, Button} from "antd";
import ListItem from "./ListItem";

Index.propTypes = {};

function Index({dataSource, footer = false, add = false}) {

    const [viewingCompleted, setViewingCompleted] = useState(false)
    let allDone = dataSource.every(it => it.percent === 100 && !it.error)
    let numberDone = dataSource.filter(it => it.percent === 100  && !it.error).length

    let betterDataSource = viewingCompleted ? dataSource : dataSource.filter(it => it.percent !== 100 || !!it.error)


    if( !betterDataSource.length){
        betterDataSource = [{
            key:'all-complete',
            title: 'All Sections Complete',
            description: <>Well Done. You have completed all sections. <div className="mt-3"><Button size="small" onClick={()=>setViewingCompleted(true)}> View Completed Sections</Button></div> </>,
            percent: 100
        }]
    }
    return <div>
        {!!numberDone > 0 && (
            <div className="relative flex justify-end pe-4" >
                <div className={"flex items-center rounded rounded-2xl p-1 border text-gray-400 border-gray-300 dark:border-neutral-700 gap-3 text-sm rounded-b-none border-b-0 bg-neutral-100 dark:bg-neutral-800"}>
                   <Switch checkedChildren="Showing Complete" unCheckedChildren={`Hiding ${numberDone} Complete`} checked={viewingCompleted} onChange={setViewingCompleted} />
                </div>

            </div>
        )}
        <List
            className="overflow-clip cursor-pointer"
            header={false}
            footer={footer}
            bordered={true}
            dataSource={betterDataSource}
            renderItem={(item, index) => {
                return <ListItem key={item.key} item={item}/>
            }}
        />




    </div>
}

export default Index;