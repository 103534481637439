import React from 'react';
import {Button} from "antd";

function CardFooterButton({children, pop, detached=false, ...props}) {
    const myStyle={
        height:'auto',
        paddingTop:'0.75rem',
        paddingBottom:'0.75rem',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    }
    if (detached){
        myStyle.marginTop = '0.5rem'
    }
    else{
        myStyle.borderTopLeftRadius= '0rem'
        myStyle.borderTopRightRadius= '0rem'
    }


    if (pop){
        myStyle.marginLeft = '-1px'
        myStyle.marginRight = '-1px'
        myStyle.marginBottom = '-1px'
        myStyle.width = 'calc(100% + 2px)'
    }

    return (
        <Button
            style={myStyle}

            {...props}
        >
            {children}
        </Button>
    );
}

export default CardFooterButton;