import React, {useEffect, useState} from 'react';
import {Auth} from "aws-amplify";

function AutoSignout(props) {
    const [user, setUser] = useState(null);
    let timeoutId = null; // Declare the timeoutId outside of state
    const checkAuthStatus = async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            setUser(currentUser);
            startSessionTimer();
        } catch (error) {
            // User is not authenticated
            setUser(null);
        }
    };
    useEffect(() => {
        checkAuthStatus();
        // Add event listeners for user interactions
        window.addEventListener('mousemove', resetSessionTimer);
        window.addEventListener('keydown', resetSessionTimer);

        return () => {
            // Remove the event listeners when the component unmounts
            window.removeEventListener('mousemove', resetSessionTimer);
            window.removeEventListener('keydown', resetSessionTimer);
        };
    }, []);



    const signOut = async () => {
        try {
            await Auth.signOut();
            setUser(null);
            clearTimeout(timeoutId);
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const startSessionTimer = () => {
        const sessionTimeoutInMs = 60 * 60 * 1000; // 1 hr

        // Clear the old timer if it exists
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // Set a new timer
        timeoutId = setTimeout(() => {
            signOut();
        }, sessionTimeoutInMs);
    };

    const resetSessionTimer = () => {
        // Reset the session timer on user interaction
        startSessionTimer();
    };

    return null
}

export default AutoSignout;