import React from 'react';
import {Button, Col, Row, Tag, Typography} from "antd";
import PropTypes from "prop-types";
import './welcome.css';
Index.propTypes = {
    title: PropTypes.any,
    description: PropTypes.any,
};
function Index({title, description, button, userMessage, icon, tag}) {
    const [loaded, setLoaded] = React.useState(false)
    React.useEffect(()=>{
        setLoaded(true)
    },[])
    return (
        <Row justify="center" className="min-h-96 flex items-center">
            <Col className="max-w-xs mb-16" >
                <div className="my-16 flex flex-col justify-center"  style={{minHeight:252}}>
                {loaded && (
                   <>
                        {!!userMessage && (
                            <div className="animation a-1 text-center">
                                <Typography.Text  className=" m-0" > <span className="text-white dark:text-indigo-100"> {userMessage} </span> </Typography.Text>
                            </div>
                        )}

                       {!!tag && (
                           <div className="animation a-1 text-center">
                               <Tag className="m-0" color="geekblue" > {tag} </Tag>
                           </div>
                       )}

                        <div className="animation a-1 mt-3">
                            <Typography.Title level={2} className="text-center my-0" > <span className="text-white dark:text-indigo-100"> {title} </span> </Typography.Title>
                        </div>
                        {icon && (
                            <div className=" dark:opacity-60">
                                <div className="flex justify-center animation a-1 text-center my-3">
                                   {icon}
                                </div>
                            </div>
                        )}
                        <div className="animation a-2 text-center my-3">
                            <Typography.Text> <span className="text-white  dark:text-indigo-100"> {description} </span> </Typography.Text>
                            {!!button && <div className="mt-4">
                                <Button onClick={button.onClick} shape="round" ghost size="large" style={{backgroundColor: 'rgba(187,194,248,0.2)'}} className="border-0 text-sm text-white dark:text-white" >{button.label}</Button>
                            </div>}

                        </div>
                   </>
                )}

</div>
            </Col>
        </Row>
    );
}

export default Index;