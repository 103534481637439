import React from 'react';
import BackgroundSvg from "./BackgroundSvg";
import {Typography} from "antd";
import Welcome from "../../../../app/components/screen/welcome";
import PageIcon from "./PageIcon";
import PageWidthLimiter from "./page-width-limiter";
import {motion} from "framer-motion";
import useScrollY from "../../../../app/components/screen/hooks/useScrollY";

function Overlay({children, colorRange, title, icon, description}) {
    const pageIcon = <PageIcon icon={icon} colorRange={colorRange}/>

    return (
        <>

            <div className="relative " data-name={title} style={{backgroundColor: colorRange[9]}}>
                <BackgroundSvg colorRange={colorRange}/>

                <div className="relative">

                    <Welcome title={title} icon={pageIcon} description={description}/>

                </div>


            </div>
            <PageWidthLimiter>
                <motion.div
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.2}}
                >
                    {children}
                </motion.div>
            </PageWidthLimiter>
        </>
    );
}

export default Overlay;