import React from 'react';
import ReactDOM from 'react-dom/client';
import {Amplify} from 'aws-amplify';
import awsConfig from './aws-exports';
import RoutesIndex from "./sussd/app/RoutesIndex";
const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
        ...awsConfig.oauth,
        domain: "oauth2.sussd.ie",
        redirectSignIn: "https://apply.sussd.ie/register/with-google/",
        redirectSignOut: "https://apply.sussd.ie/login/",
    }
}

Amplify.configure(updatedAwsConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RoutesIndex/>);