import React, {useContext, useState} from 'react';
import {MortgageTypes} from "../../../../../../models";
import {Button, Card, Tabs, List} from "antd";
import {Mortgage} from "../../../data-source/providers/MortgageProvider";
function MortgageJourney() {
    const mortgage = useContext(Mortgage)
    const myStage = mortgage ? mortgage.applicationStage : null
    let stages = []
    if (mortgage && mortgage !== 'fetching') {
        const setupStage = {
            name: 'SETUP',
            title: 'Setup',
            description: 'This is where you define your mortgage needs and provide basic information about your financial situation.',
            data: [
                {
                    heading: 'Complete the Setup',
                    description: 'Fill in the sections about your mortgage preferences and financial details.'
                },
                {
                    heading: 'Compliance Check',
                    description: 'We make sure your needs comply with the Central Bank of Ireland’s guidelines.'
                },
                {
                    heading: 'Financial Review',
                    description: 'We review your financial information to match you with suitable lenders.'
                },
                {
                    heading: 'Document Verification',
                    description: 'Upload your bank statements and other required financial documents to verify your financial details.'
                },
                {
                    heading: 'Follow-Up',
                    description: 'We’ll contact you to discuss your application and next steps.'
                }
            ]
        }
        const applyStage = {
            name: 'APPLY',
            title: 'Apply',
            description: 'This is where you will fill out application for a loan and upload your supporting documentation.',
            data: [
                {
                    heading: 'Complete the Application Form',
                    description: 'Fill in the application form with your personal details and more in-depth financial information. Upload all the remaining required documents.'
                },
                {
                    heading: 'Review',
                    description: 'We review your application to ensure all required information is complete and to verify that your in-depth financial data is accurate and free of any unexpected issues.'
                },
                {
                    heading: 'Additional Information',
                    description: 'In most cases, we will create a short list of queries for you to answer, related to the information in your documentation. '
                },
                {
                    heading: 'Submission',
                    description: 'We submit your application to the lender for their review.'
                }
            ]
        }
        let preapprovalStageData = []
        if (mortgage.mortgageType === MortgageTypes.PURCHASE) {
            preapprovalStageData.push({
                heading: 'Settle on a property',
                description: 'If you haven\'t already done so, you need to settle on a property to proceed with the mortgage application. '
            })
        }
        preapprovalStageData.push(
            {
                heading: 'Complete the Tasks',
                description: 'We prepare a list of tasks for you to complete such as getting a valuation and providing your solicitor information'
            },
            {
                heading: 'Formal Loan Offer',
                description: 'We request the loan offer from the lender so that the legalities can be completed'
            }
        )
        const preapprovalStage = {
            name: 'PREAPPROVAL',
            title: 'Approval In Principle',
            description: 'At this stage, the lender is ready to work with you if you finish some tasks in a set time. Once you complete these tasks, we can apply for "Full Mortgage Approval."',
            data: preapprovalStageData
        }

        const approvalStage = {
            name: 'APPROVAL',
            title: 'Mortgage Drawdown',
            description: 'This is where you will perform the final tasks required to get the funds released from your mortgage.',
            data: [
                {
                    heading: 'Complete the Tasks',
                    description: 'We prepare a list of tasks for you to complete such as uploading your most recent payslips and bank statements'
                },
                {
                    heading: 'Draw Down',
                    description: 'After your solicitors has completed their duties, we request the lender release the funds to your solicitor'
                }
            ]
        }
        stages = [
            setupStage,
            applyStage,
            preapprovalStage,
            approvalStage
        ]
    }
    const activeSlide = stages.findIndex(stage => stage.name === myStage) || 0
    const [state, setState] = useState({activeSlide})
    const handleNext = () => {
        setState(s => ({...s, activeSlide: state.activeSlide + 1}))

    }
    const handlePrev = () => {
        setState(s => ({...s, activeSlide: state.activeSlide - 1}))
    }
    const setActiveSlide = (name) => {
        const activeSlide = stages.findIndex(stage => stage.name === name)
        setState(s => ({...s, activeSlide}))
    }

    const items = stages.map((stage, i) => {
        return {
            key: stage.name,
            label: `${i + 1}. ${stage.title}`,
            children: (
                <>
                    <div className="my-9">
                        {stage.description}
                    </div>
                    <List
                        size="small"
                        dataSource={stage.data}
                        renderItem={item => (
                            <List.Item>
                                <div className="w-full flex flex-col sm:flex-row gap-3">
                                    <div className="w-48 font-semibold flex-shrink-0">
                                        {item.heading}:
                                    </div>
                                    {item.description}
                                </div>
                                {/*<span className="flex gap-3 items-center">*/}
                                {/*<CheckCircleOutlined className="text-cyan-500"/>*/}
                                {/*{item}*/}
                                {/*</span>*/}

                            </List.Item>
                        )}
                    />
                </>
            )
        }
    })

    return (
        <>
            <Card>
                <Tabs activeKey={stages[state.activeSlide]?.name} items={items} onChange={setActiveSlide}/>
                <div className="flex gap-3 mt-4">
                    {state.activeSlide !== 0 && (
                        <Button size="small" shape="round" onClick={handlePrev}>Previous</Button>
                    )}
                    {state.activeSlide !== stages.length - 1 && (
                        <Button className="ms-auto" size="small" shape="round" onClick={handleNext}>Next</Button>
                    )}
                </div>
            </Card>
        </>
    )

}

export default MortgageJourney;