import React, {useContext} from 'react';
import {ConfigProvider, theme} from "antd";
import PropTypes from 'prop-types';
import Nav from "./nav";
import './screen.css'
import {DarkModeContext} from "./DarkModeProvider";
import {cyan} from "@ant-design/colors";
import {OverlayContext} from "./overlay/CSSOverlayProvider";
Screen.propTypes = {
    xs: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
};
const {defaultAlgorithm, darkAlgorithm} = theme

function Screen({children, user, title, navbarColor, ...sizes}) {
    const [darkMode, toggleDarkMode] = useContext(DarkModeContext);
    const {Overlays} = useContext(OverlayContext)
    let className = darkMode ? "dark" : ""

    return (

        <div className={`body ${className}`}>

            <ConfigProvider
                theme={{
                    components: {
                        Message: {
                            zIndexPopup: 200
                        },
                        Typography: {
                            titleMarginTop: 0,
                            titleMarginBottom: 0
                        },
                        Notification: {
                            zIndexPopup: 200
                        },
                        Card: {
                            // borderRadiusLG: '1.1rem',
                            colorBorderSecondary: '#d9d9d9',
                        }
                    },
                    token: {
                        fontFamily: 'Poppins',
                        borderRadius: '1.1rem',
                        colorPrimary: cyan[6],
                    },
                    algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
                }}
            >
                    <div className="pb-4">
                        {children}

                    </div>


                    <Nav darkMode={darkMode} bgColor={navbarColor} toggleDarkMode={toggleDarkMode} {...sizes}
                         title={title}/>
                    {Overlays}
            </ConfigProvider>

        </div>

    );
}
export function ScreenImage({src,darkSrc, children}) {

    const [darkMode] = useContext(DarkModeContext)
    return (
        <div className="pt-10 screen-image" style={{backgroundImage: `url(${darkMode ? darkSrc : src})`}}>
            {children}
        </div>
    )
}
export default Screen;