/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "sussdAdminAPI",
            "endpoint": "https://zgc8klhgzc.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "sussdUserAPI",
            "endpoint": "https://eg5q8g9345.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5h42f3flqvdjvh4zgp3n5qygju.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:c2a2918d-4f9d-4fce-b9bd-f2aad842077b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_KATxP3ViP",
    "aws_user_pools_web_client_id": "2a9idub53g71bahuq4fmhhktnj",
    "oauth": {
        "domain": "sxt05wes01ml-staging.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://apply.sussd.ie,http://localhost:8000,https://apply.sussd.ie/register/with-google/,http://localhost:8000/register/with-google/",
        "redirectSignOut": "https://apply.sussd.ie,http://localhost:8000,https://apply.sussd.ie/login/,http://localhost:8000/login/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "sussd-user-files55436-staging",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "search_indices": {
                "items": [
                    "sussdBackendPlaces-staging"
                ],
                "default": "sussdBackendPlaces-staging"
            }
        }
    }
};


export default awsmobile;
